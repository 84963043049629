<template>
    <div class="container  mt-3">
      <div class="m-auto">
          
        <div class="form-horizontal">
          <div class="form-group" v-if="estudiante">
            <div class="row">
              <label class="col-3 col-sm-4 col-form-label">Habilitar tutoria</label>
              <div class="col-3 col-sm-4">
                <select v-model="habilitar_tutoria" class="form-control" @change="actualizar($event)">
                  <option disabled value="">Please select one</option>
                  <option  value="1">SI</option>
                  <option  value="0">NO</option>
                </select>
              </div>
            </div>
            <div class="row mt-3 mb-3" v-if="habilitar_tutoria == '1'">
              <hr>
              <div class="col-3 col-sm-6">
                <div class="form-group row">
                  <label class="col-3 col-form-label">Inicia:</label>
                  <div class="col-9">
                    <date-picker  class="date-picker" v-model="fecha_tutoria_inicio" lang="es" type="date" :time-picker-options="timePickerOptions" :clearable="true"></date-picker>
                  </div>
                </div>
              </div>
              <div class="col-3 col-sm-6">
                <div class="form-group row">
                  <label class="col-3 col-form-label">Finaliza:</label>
                  <div class="col-9">
                    <date-picker  class="date-picker" v-model="fecha_tutoria_fin" lang="es" type="date" :time-picker-options="timePickerOptions" :clearable="true"></date-picker>
                  </div>
                </div>
              </div>
              <div class="col-3 col-sm-4">
                <div class="form-group row">
                  <label class="col-3 col-form-label">Tutor:</label>
                  <div class="col-9">
                    <select v-model="tutor_id" class="form-control">
                      <option value="">Seleccionar aleatoriamente</option>
                      <option v-for="tutor in tutores" :value="tutor.id">{{ tutor.primer_nombre }} {{ tutor.segundo_nombre }}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-3 col-sm-6">
                <InputNumero titulo="Tutorias" :modelo="tutorias" @change="tutorias=$event"/>
              </div>

            </div>
            
            <div class="row">
              <div class="col-4"></div>
              <div class="col-4">
                <button v-if="procesable" class="btn btn-block btn-danger" @click="procesar">
                  Guardar
                </button>
              </div>
            </div> 
          </div>
          <div v-else>
            No hay cursos inscritos
          </div>
          
        </div>
      </div>
    </div>
  </template>
  
  <script>
    import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
    import DatePicker from 'vue2-datepicker'
    
    export default {
      name: 'DetalleTutoria',
      components: {
        DatePicker
      },
      data () {
        return {
            habilitar_tutoria:null,
            fecha_tutoria_inicio:null,
            fecha_tutoria_fin:null,
            tutor_id:null,
            tutorias:null,
            timePickerOptions:{}
        }
      },
      props: {
        estudiante: Object
      },
      mounted () {
        if(this.estudiante){
          this.habilitar_tutoria = this.estudiante.habilitar_tutoria;
          if (this.estudiante.fecha_tutoria_inicio) {
            this.fecha_tutoria_inicio = new Date(this.estudiante.fecha_tutoria_inicio);
          }
          if (this.estudiante.fecha_tutoria_fin) {
            this.fecha_tutoria_fin = new Date(this.estudiante.fecha_tutoria_fin);
          }
          if (this.estudiante.tutorias) {
            this.tutorias = this.estudiante.tutorias;
          }
          if (this.estudiante.tutor_id) {
            this.tutor_id = this.estudiante.tutor_id;
          }
        }
        this.fetchTutores();
      },
      methods:{
        ...mapActions({
            editarHabilitarTutoria: 'estudiantes/editarHabilitarTutoria',
            fetchDetalle: 'estudiantes/fetchDetalle',
            fetchTutores: 'docentes/fetchTutores',
            setWarning: 'setWarning',
        }),
        ...mapMutations({
          
        }),
        procesar(){
          this.editarHabilitarTutoria({
            id:this.estudiante.id,
            habilitar_tutoria:this.habilitar_tutoria,
            tutorias:this.tutorias,
            fecha_tutoria_inicio:this.fecha_tutoria_inicio,
            fecha_tutoria_fin:this.fecha_tutoria_fin,
            tutor_id:this.tutor_id
          }).then((apiResponse)=>{
            
            this.limpiar()
            this.setWarning('Información Guardada', { root: true }).then(()=>{
              this.fetchDetalle(this.estudiante.id).then(()=>{
                this.$router.push('/estudiante/detalle/'+this.estudiante.id)
              }) 
            })
            
          }).catch((apiResponse)=>{
            console.log("error procesando editar requiere clase 0.")
          })
        },
        limpiar(){
         this.habilitar_tutoria  = null
        },
        actualizar(event) {
          if (this.habilitar_tutoria) {
            this.tutorias = 2;
            this.fecha_tutoria_inicio = new Date();
            let next_date = this.$moment(this.fecha_tutoria_inicio);
            this.fecha_tutoria_fin = next_date.add(5, 'days').toDate();
            let tutor = this.tutores.find(x => x.id == this.user.data.id);
            if (!tutor) {
              tutor = this.tutores.find(x => x.numero_documento == this.user.data.numero_documento);
            }
            if (tutor) {
              this.tutor_id = tutor.id;
            } else {
              this.tutor_id = null;
            }
          } else {
            this.tutorias = null;
            this.fecha_tutoria_inicio = null;
            this.fecha_tutoria_fin = null;
            this.tutor_id = null;
          }
        }
      },
      computed:{
        ...mapState({
          
          tutores: state  => state.docentes.tutores,
          user: state => state.auth.user, 
        }),
        ...mapGetters({
          
        }),
        procesable: {
          get () {
            return ((this.habilitar_tutoria != null && this.habilitar_tutoria!=this.estudiante.habilitar_tutoria) 
            || (this.tutorias != null && this.tutorias!=this.estudiante.tutorias)
            || (this.fecha_tutoria_inicio != null && this.fecha_tutoria_inicio.getTime()!=new Date(this.estudiante.fecha_tutoria_inicio).getTime())
            || (this.fecha_tutoria_fin != null && this.fecha_tutoria_fin.getTime()!=new Date(this.estudiante.fecha_tutoria_fin).getTime())
            || (this.tutor_id != null && this.tutor_id != this.estudiante.tutor_id)
          )
          },
          set(value){}
        },
      }
    }
  </script>
  
  <style scope>
    .programar_wrapper .form-container{
        
    }
      .programar_wrapper{
      
      }
  </style>
  
  