<template>
    <div class="container  mt-3">
      <div class="m-auto">
          
        <div class="form-horizontal">
          
            <div class="form-group row mb-3">
              <label class="col-3 col-sm-4 col-form-label">Niveles Disponibles</label>
              <div class="col-6 col-sm-8">
                <b-table striped hover :items="lista" :striped="true" :outlined="true" :small="true" :fixed="true" :fields="fields">
                  <template #cell(seleccionado)="data">
                    <b-form-checkbox v-model="data.item.seleccionado" name="check-button" button @change="cambiar(data.item, $event)" size="sm" :button-variant="data.item.seleccionado ? 'success' : ''">
                      <b>{{ data.item.seleccionado ? 'SI' : 'NO' }}</b>
                    </b-form-checkbox>
                  </template>
                </b-table>
              </div>
              <div class="col-3 offset-sm-4 col-sm-4">
                <button v-if="procesable" class="btn btn-block btn-danger" @click="observacionSeccion=true">
                  Actualizar
                </button> 
              </div>  
            </div>
        </div>
      </div>
      <b-modal no-close-on-backdrop hide-header-close @cancel="cancelarActualizacion"
     @ok="procesar" v-model="observacionSeccion"  id="modalObservacionSeccion"  
     :ok-disabled="observaciones.length<observacionesDefinirMin"
      title="Actualizar Niveles">

      <h5> ¿Estás seguro que desea actualizar los niveles?</h5>
      <!--v-if=""-->
     <div class="mb-2">
      <b-form-textarea
          id="textarea1"
          v-model="observaciones"
          placeholder="Cuéntanos, por qué es necesario actualizar?"
          rows="3"
          max-rows="6"
        ></b-form-textarea>
        <pre class="d-block text-right">{{ observaciones.length }}/{{ observacionesDefinirMin }}</pre>
         </div>

     </b-modal>
    </div>
  </template>
  
  <script>
    import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
  
    export default {
      name: 'DetalleNivelesDisponibles',
      components: {
        
      },
      data () {
        return {
            niveles:[],
            observaciones:'',
            observacionesDefinirMin: 10,
            observacionSeccion: false,
            fields:['idioma', 'descripcion', 'seleccionado']
        }
      },
      props: {
        estudiante: Object
      },
      mounted () {
        if(!this.opciones || (this.opciones && this.opciones.length==0)){
          this.fetchNiveles()
        }
        if(this.estudiante){
          this.niveles = this.estudiante.niveles.map(x => x.id)  
        }else{
          console.log('Hay que asegurarse de tener un estudiante antes de utilizar el componente DetalleCursosDisponibles')
        }
      },
      methods:{
        ...mapActions({
          actualizarNiveles: 'estudiantes/actualizarNiveles',
          fetchNiveles: 'niveles/fetchLista',
          fetchDetalle: 'estudiantes/fetchDetalle',
          setWarning: 'setWarning',
        }),
        ...mapMutations({
          
        }),
        procesar(){
          this.actualizarNiveles({
            id:this.estudiante.id,
            niveles:this.niveles,
            observaciones:this.observaciones
          }).then((apiResponse)=>{
            this.limpiar()
            this.setWarning('Cursos actualizados', { root: true }).then(()=>{
              this.fetchDetalle(this.estudiante.id).then(()=>{
                this.$router.push('/estudiante/detalle/'+this.estudiante.id)
              }) 
            })
            
            
          })
        },
        limpiar(){
          this.cancelarActualizacion();
          this.niveles = []
        },
        cancelarActualizacion(){
          this.observaciones = ''
          
        },
        cambiar(modelo, $event) {
          if ($event) {
            this.niveles.push(modelo.id);
          } else {
            var index = this.niveles.indexOf(modelo.id);
            if (index > -1) {
              this.niveles.splice(index, 1);
            }
          }
        }
      },
      computed:{
        ...mapState({
          opciones: state  => state.niveles.lista,
        }),
        ...mapGetters({
          
        }),
        procesable: {
          get () {
            return (this.niveles && JSON.stringify(this.niveles.sort()) != JSON.stringify(this.estudiante.niveles.map(x => x.id).sort()))
          },
          set(value){}
        },
        lista() {
          if (this.opciones && this.niveles) {
            var _opciones = [...this.opciones];
            _opciones.forEach((x) => {
              let esta = this.niveles.find(n => n == x.id);
              if (esta) {
                x.seleccionado = true;
              } else {
                x.seleccionado = false;
              }
            })
            return _opciones;
          }
          return [];
        },
      }
    }
  </script>
  
  <style scope>
    .programar_wrapper .form-container{
        
    }
      .programar_wrapper{
      
      }
  </style>
  
  