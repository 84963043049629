import Vue from 'vue'
import Vuex from 'vuex'
import auth from '@/modules/auth';
import estudiantes from '@/modules/estudiantes';
import docentes from '@/modules/docentes';
import programacion from '@/modules/programacion';
import salones from '@/modules/salones';
import cursos from '@/modules/cursos';
import clases from '@/modules/clases';
import asw from '@/modules/asw';
import banner from '@/modules/banner';
import agendamientos from '@/modules/agendamientos';
import progresos from '@/modules/progresos';
import funcionarios from '@/modules/funcionarios';
import grupos from '@/modules/grupos';
import temas from '@/modules/temas';
import permisos from '@/modules/permisos';
import indicadores from '@/modules/indicadores';
import sedes from '@/modules/sedes';
import estado from '@/modules/estado';
import preasignaciones from '@/modules/preasignaciones';
import examenes from '@/modules/examenes';
import tipoDocumento from '@/modules/tipoDocumento';
import correos from '@/modules/correos';
import niveles from '@/modules/niveles';
import tutorias from '@/modules/tutorias';
import {version} from '../package.json';

import router from './router'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    processing: false,
    warning: null,
    error: null,
    packageVersion : version,
    isDebug: false,
    baseUrl: process.env.BASE_URL,
    nodeEnv: process.env.NODE_ENV,
    appInstance: process.env.VUE_APP_INSTANCE
  },
  getters:{

  },
  mutations: {
    startProcessing (state) {
      state.processing = true;
    },
    stopProcessing (state) {
      state.processing = false;
    },
    mapError(state, data) {
      if(data.name && data.name=='InvalidJwtToken'){
        state.error = 'Acceso no autorizado. Debe autenticarse nuevamente.';
        this.dispatch('auth/logout')
        router.push('/login')
      }else{
        state.error = data.message;  
      }
    },
    setError (state, responseApi) {
      console.log(responseApi);
      if(responseApi.data){
        let data = responseApi.data;
        let data_error = data && data.error && data.error.message ? data.error : data;
        if(data_error){
          if(data_error.message){
            if(data_error.name && data_error.name=='InvalidJwtToken'){
              state.error = 'Acceso no autorizado. Debe autenticarse nuevamente.';
              this.dispatch('auth/logout')
              router.push('/login')
            }else{
              state.error = data_error.message;  
            }
            
          }else{
            if (data_error.error) {
              state.error = data_error.error;
            } else {
              state.error = data_error;
            }
          }  
        }else{
          state.error = data;
        }
      }else{
        state.error = responseApi;
      }
      
      setTimeout(() => {
        state.error = null
      },3000)
    },
    
    setDebug (state, value) {
      state.isDebug = value;
    },
  },
  actions: {
    setWarning ({state}, value) {
      state.warning = value
      return new Promise((resolve) => {
        setTimeout(function () {
          state.warning = null
          resolve()
        }, 2000)
      })
    },
    fetchVersion: ({commit}) => {
      return new Promise((resolve, reject) => {
        Vue.http.post('version')
          .then(response => {
              commit('setVersion',response.data);
              resolve(response.data);
          })
          .catch(response => {
            reject(response);
          })
          .finally(() => {})
      })
    },
  },
  modules: {
    auth,
    estudiantes,
    docentes,
    programacion,
    salones,
    cursos,
    clases,
    agendamientos,
    asw,
    banner,
    progresos,
    funcionarios,
    grupos,
    temas,
    permisos,
    indicadores,
    sedes,
    estado,
    preasignaciones,
    examenes,
    tipoDocumento,
    correos,
    niveles,
    tutorias
  }
})


export default store;
