import Vue from 'vue';

const state = {
  tutorias : {},
  grupos_salones: {},
  agenda: {},
  progresos : {},
};

const actions = {
  
  fetchAgendamientos:({commit}) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.post('agendamientos').then(
        response =>{
          commit('setAgendamientos',response.data);
          resolve(response.data)
        }
      ).catch(error=>{
        commit('setError', error.data, { root: true });
        reject(error)
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },
  fetchAgendasxFecha:({commit},payload) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.post('tutorias/agenda-x-fecha',payload).then(
        response =>{
          commit('setAgendasXMarcaTiempo',response.data);
          resolve(response.data)
        }
      ).catch(error=>{
        commit('setError', error, { root: true });
        reject(error)
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },
  fetchTutoriasxFecha:({commit},payload) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.post('tutorias/tutoria-x-fecha',payload).then(
        response =>{
          commit('setTutoriasXMarcaTiempo',response.data);
          resolve(response.data)
        }
      ).catch(error=>{
        commit('setError', error, { root: true });
        reject(error)
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },
  //guarda la clase del agendamiento
  //payoload = 
  setClase:({commit},payload) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve,reject) => {
      Vue.http.post('agendamiento/set_clase',payload).then(
        response =>{
          if(response.data.clase_id){
            commit('clases/setAgendamiento',response.data,{ root: true });
          }else{
            
            commit('setAgendamiento',response.data);
          }
          resolve(response.data)
        }
      ).catch(error=>{
        commit('setError', error, { root: true });
        reject(error)
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },
  desasignarDeClase:({commit},agendamiento) => {
    commit('clases/deleteAgendamiento',agendamiento,{ root: true });
  },
  desasignarDePendientes:({commit},agendamiento) => {
    commit('deleteAgendamiento',agendamiento);
  },
  //solicita asignar una clase automaticamente
  asignarAuto:({commit},payload) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve,reject) => {
      Vue.http.post('agendamiento/asignar_clase_auto',payload).then(
        response =>{
          if(response.data.clase_id){
            commit('clases/setAgendamiento',response.data,{ root: true });
          }else{
            commit('setAgendamiento',response.data);
          }
          resolve(response.data)
        }
      ).catch(error=>{
        commit('setError', error, { root: true });
        reject(error)
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },
  //guarda la clase del agendamiento
  setTema:({commit},payload) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve) => {
      Vue.http.post('agendamiento/set_tema',payload).then(
        response =>{
          if(response.data.clase_id){
            commit('clases/setAgendamiento',response.data,{ root: true });
          }else{
            commit('setAgendamiento',response.data);
          }
          resolve(response.data)
        }
      ).catch(error=>{
        console.log('error:'+error)
        console.log(error)
        commit('setError', error, { root: true });
        //reject(error)
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },

  asignarTemaAuto:({commit},payload) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve) => {
      Vue.http.post('agendamiento/asignar_tema_auto',payload).then(
        response =>{
          if(response.data.clase_id){
            commit('clases/setAgendamiento',response.data,{ root: true });
          }else{
            commit('setAgendamiento',response.data);
          }
          resolve(response.data)
        }
      ).catch(error=>{
        console.log('error:'+error)
        console.log(error)
        commit('setError', error, { root: true });
        //reject(error)
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },

  anularFirma:({commit},payload) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve) => {
      Vue.http.post('agendamiento/anular_firma',payload).then(
        response =>{
          if(response.data.clase_id){
            commit('clases/setAgendamiento',response.data,{ root: true });
          }else{
            commit('setAgendamiento',response.data);
          }
          resolve(response.data)
        }
      ).catch(error=>{
        console.log('error:'+error)
        console.log(error)
        commit('setError', error, { root: true });
        //reject(error)
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },

  
  eliminarTema:({commit},payload) => {
    commit('startProcessing', null, { root: true });

    return new Promise((resolve) => {
      Vue.http.post('agendamiento/eliminar_tema',payload).then(
        response =>{
          if(response.data.clase_id){
            commit('clases/setAgendamiento',response.data,{ root: true });
          }else{
            commit('setAgendamiento',response.data);
          }
          resolve(response.data)
        }
      ).catch(error=>{
        console.log('error:'+error)
        console.log(error)
        commit('setError', error, { root: true });
        //reject(error)
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },

  fetchProgreso:({commit},data) => {
      commit('startProcessing', null, { root: true });
      return new Promise((resolve, reject) => {
        Vue.http.post('estudiante/tutoria/progreso',data).then(
          response =>{
            commit('setProgreso',response.data);
            resolve(response.data)
          }
        ).catch(response=>{
          commit('setError', response, { root: true });
          reject(response)
        }).finally(()=>{
          commit('stopProcessing', null, { root: true });
        })
      });
    }
  
  
};

const getters = {
  getProgreso: (state) => (estudiante_id) => {
    if(!state.progresos[estudiante_id]){
      Vue.set(state.progresos, estudiante_id, {})
    }
    return state.progresos[estudiante_id]
  },
};

const mutations = {
  setAgendamientos: (state, datos) => {
    state.agendamientos = datos
  },
  setAgendamiento: (state, datos) => {
    console.log('agendamientos/setAgendamiento')
    //agrego al arreglo de agendamientos pendientes
    if(!datos.clase_id){
      if(datos.marca){
        console.log('buscando marca:'+datos.marca)
        if(!state.agendamientos[datos.marca]){
          Vue.set(state.agendamientos, datos.marca, {})
        }
        let index = state.agendamientos[datos.marca].findIndex(element =>{
          return element.id == datos.id
        })
        console.log('setAgendamiento: index:'+index)
        if(index>=0){ 
          state.agendamientos[datos.marca].splice(index,1,datos)
        }else{
          state.agendamientos[datos.marca].push(datos)
        }
      }else{
        console.log('setAgendamiento: resultado sin marca')
      }
    }else{
      console.log('error intentando agregar un agendamiento asignado al arreglo de pendientes')
    }
  },
  setAgendamientosXMarca: (state, datos) => {
    let marca = datos.marca;
    if(!state.agendamientos[marca]){
      Vue.set(state.agendamientos, marca, {})  
    }
    state.agendamientos[marca] = datos.agendamientos
  },
  setAgendasXMarcaTiempo: (state, datos) => {
    if(!state.agenda[datos.fecha]){
      Vue.set(state.agenda, datos.fecha, {})  
    }
    state.agenda[datos.fecha] = datos.agenda;
  },
  setTutoriasXMarcaTiempo: (state, datos) => {
    if(!state.tutorias[datos.fecha]){
      Vue.set(state.tutorias, datos.fecha, {})  
    }
    state.tutorias[datos.fecha] = datos.agendamientos;
    state.grupos_salones[datos.fecha] = datos.grupos_salones;
    
  },
  deleteAgendamiento: (state, datos) =>{
    let marca = datos.fecha+'T'+datos.hora+'T'+datos.tipo_clase
    console.log('deleteAgendamiento marca:'+marca)
    
    let index = state.agendamientos[marca].findIndex(element =>{
      return element.id == datos.id
    })
    console.log('index:'+index)
    if(index>=0){
      state.agendamientos[marca].splice(index,1)
    }  
    
  },
  setProgreso : (state, datos) => {
    let estudiante_id = datos.estudiante.id
    if(!state.progresos[estudiante_id]){
      Vue.set(state.progresos, estudiante_id, {})  
    }
    state.progresos[estudiante_id] = datos
  },
  restart: (state) => {
    state.agendamientos = {}
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
