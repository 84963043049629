<template>
  <div class="container">

    <div class="agendamiento_fila row" v-if="agendamiento">
        <div class="col-md-3">
          <strong class="cap">{{textoTipoSolicitud}} / {{textoTipoClase}}</strong><br>
          <span>{{textoCurso}}</span><br>
          <strong>{{textoTema}}</strong><br>
          <span>{{textoSede}} - {{textoFechaClase}}</span><br>
          <span>{{textoSalon}}</span><br>
          
        </div>
        <div class="col-md-3">
          <strong class="cap">{{textoEstado}}</strong><br>
          <span>{{textoAsistenciaFecha}}</span><br>
          <button v-if="permiso('98E38A0E') && puedeCancelar" @click="cancelando=true" class="btn btn-danger btn-sm" >Cancelar Agendamiento</button>

        </div>
        <div class="col-md-6">
          <p v-for="observacion in observaciones">
            {{ observacion.texto}}<span>  {{ observacion.usuario}} / {{ observacion.fecha}} </span><br>
          </p>
        </div>
        
       

      <!--
      <div class="col-md-5 observaciones">
          <DetalleAgendamientoObservaciones @addNota="addNota"  :row="agendamiento" />  
      </div>
    -->
    </div>
    
    <b-modal @ok="procesarCancelar" @cancel="motivoCancelado=''" v-model="cancelando"  id="modalCancelando"
    :ok-disabled="motivoCancelado.length<motivoCanceladoMin"  title="Motivo de la cancelación">
      <div class="mb-2">
        <b-form-textarea
          id="textarea"
          v-model="motivoCancelado"
          placeholder="Cuéntanos, por qué es necesario hacer esta cancelación manual?"
          rows="3"
          max-rows="6"
        ></b-form-textarea>
        <pre class="d-block text-right">{{ motivoCancelado.length }}/{{ motivoCanceladoMin }}</pre>
      </div>
    </b-modal>

  </div>
</template>

<script>
  import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';

  export default {
    name: 'DetalleAgendamientoTutoriasItems',
    components: {
    },
    props:{
      agendamiento: Object,
      estudiante: Object
    },
    data () {
      return {
        tema_id_local : null,
        texto_cambio_tipo : null,
        cambiando_tema : false,
        texto_cambio_tipo: null,
        cancelando: false,
        motivoCancelado: '',
        motivoCanceladoMin: 20
      }
    },
    
    mounted () {
      
    },
    methods:{
      ...mapActions({
        fetchTutorias: 'estudiantes/fetchTutorias',
        cancelar: 'programacion/cancelarTutoria',
      }),
      ...mapMutations({

      }),
      recargar(){
        
      },
      addNota(datos){
        //this.saveObservacion({agendamiento_id:datos.id,texto:datos.texto}).catch(()=>{})
      },
      procesarCancelar(){
        let payload = {
          tutoria_id:this.agendamiento.id,
          motivo:this.motivoCancelado
        }
        this.cancelar(payload).then(()=>{
          this.fetchTutorias(this.estudiante.id);
        })
      },

    },
    computed: {
      ...mapState({
        
      }),
      ...mapGetters({
        permiso: 'auth/permiso', 
      }),
      temaIdLocal:{
        get () {
          return this.tema_id_local
        },
        set (value) {
          this.tema_id_local = value
          if(this.tema_id_local.tipo_clase != this.agendamiento.tipo_clase){
            this.texto_cambio_tipo = "El estudiante deberá cambiar de clase"
          }else{
            this.texto_cambio_tipo = null
          }
        }
      },
      puedeCancelar(){
        if(this.agendamiento){
          if( this.agendamiento.estado=='solicitado' || this.agendamiento.estado=='asignado'){
             return true;              
          }
        }
        return false
      },

      textoSalon(){
        if(this.agendamiento && this.agendamiento.clase && this.agendamiento.clase.salon){
          let salon = this.agendamiento.clase.salon
          let texto = (this.textoSede +' / '+salon.nombre)
          return texto
        }
        return ''
      },
      textoSede(){
        if(this.agendamiento && this.agendamiento.clase && this.agendamiento.clase.sede){
          let texto = this.agendamiento.clase.sede ? this.agendamiento.clase.sede.nombre : "";
          return texto
        }
        return ''
      },
      textoTipoClase(){
        if(this.agendamiento && this.agendamiento.clase){
          let texto = this.agendamiento.clase.tipo
          return texto
        }
        return ''
      },
      textoTipoSolicitud(){
        if(this.agendamiento && this.agendamiento.clase){
          let texto = this.agendamiento.clase.tipo
          return texto
        }
        return ''
      },
      textoTema(){
        if(this.agendamiento && this.agendamiento.tema){
          let texto = this.agendamiento.tema.nombre
          return texto
        }
        return ''
      },
      textoCurso(){
        if(this.agendamiento.curso && this.agendamiento.curso.nombre){
          let texto = this.agendamiento.curso.nombre
          return texto
        }
        return ''
      },
      observaciones(){
        if(this.agendamiento && this.agendamiento.observaciones){
          return  JSON.parse(this.agendamiento.observaciones)
        }
        return null
      },
      textoFechaClase(){
        if(this.agendamiento && this.agendamiento.clase){
          let clase_fecha = new Date(this.agendamiento.clase.fecha_bloque).toLocaleString()
          return clase_fecha
        }
        return ''
      },
      textoAsistenciaFecha(){
        if(this.agendamiento && this.agendamiento.asistencia_fecha){
          let asistencia_fecha = new Date(this.agendamiento.asistencia_fecha).toLocaleString()
          return asistencia_fecha
        }
        return ''
      },
      
      textoEstado(){
        if(this.agendamiento){
          if( this.agendamiento.estado=='solicitado'){
            return 'Solicitado'
          }else{
            return this.agendamiento.estado  
          }
          
        }
        return ''
      },
      textoConformidadFecha(){
        if(this.agendamiento && this.agendamiento.conformidad_fecha){
          let conformidad_fecha = new Date(this.agendamiento.conformidad_fecha).toLocaleString()
          return conformidad_fecha
        }
        return 'Pendiente'
      },
      
    }
  }
</script>

<style scope>
.agendamiento_fila {
    border-top: 1px solid #bbb;
    font-size:14px;
    line-height: 14px;
    padding-top: 10px !important;
}
.agendamiento_fila  strong{}
.agendamiento_fila  span{
  font-size: 10px;
  line-height: 10px;
  color:#888;
  font-style: oblique;
}
.agendamiento_fila  p{
      margin-bottom: 5px !important;
}
.agendamiento_fila  button{
      margin: 5px !important;
}
.cambiar-tema{
  padding: 10px;
    border: 1px solid grey;
}
.cap{
text-transform: capitalize;  
}
</style>