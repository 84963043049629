<template>
  <div class="container  mt-3">
    <div class="m-auto">
    	
      <div class="form-horizontal">
        <div class="form-group row" v-if="estudiante">
          <label class="col-3 col-sm-4 col-form-label">Sábados</label>
          <div class="col-3 col-sm-4">
            <select v-model="sabados" class="form-control">
              <option disabled value="">Please select one</option>
              <option  value="1">Incluidos</option>
              <option  value="0">No Incluidos</option>
            </select>
          </div>
          <div class="col-3 col-sm-4">
              <button v-if="procesable" class="btn btn-block btn-danger" @click="procesar">
                Guardar
              </button>
            </div> 
        </div>
        <div v-else>
          No hay cursos inscritos
        </div>
        
      </div>
    </div>
  </div>
</template>

<script>
  import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
  
  export default {
    name: 'DetalleSabados',
    data () {
      return {
        sabados:null
      }
    },
    props: {
      estudiante: Object
    },
    mounted () {
      if(this.estudiante){
        this.sabados = this.estudiante.sabados
      }
    },
    methods:{
      ...mapActions({
        editarSabados: 'estudiantes/editarSabados',
        fetchDetalle: 'estudiantes/fetchDetalle',
        setWarning: 'setWarning',
      }),
      ...mapMutations({
        
      }),
      procesar(){
        this.editarSabados({
          id:this.estudiante.id,
          sabados:this.sabados,
          
        }).then((apiResponse)=>{
          
          this.limpiar()
          this.setWarning('Información Guardada', { root: true }).then(()=>{
            this.fetchDetalle(this.estudiante.id).then(()=>{
              this.$router.push('/estudiante/detalle/'+this.estudiante.id)
            }) 
          })
          
        }).catch((apiResponse)=>{
          console.log("error procesando editar sabados.")
        })
      },
      limpiar(){
       this.sabados  = null
      }
    },
    computed:{
      ...mapState({
        
      }),
      ...mapGetters({
        
      }),
      procesable: {
        get () {
          return (this.sabados != null && this.sabados!=this.estudiante.sabados)
        },
        set(value){}
      },
    }
  }
</script>

<style scope>
  .programar_wrapper .form-container{
      
  }
	.programar_wrapper{
    
	}
</style>

