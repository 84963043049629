<template>

    <div class="container programaciones_wrapper">      
      <div class="tabla" v-if="tutorias">
        <b-button v-b-toggle.collapseTutorias variant="link"><h2>Tutorias</h2></b-button>

        <b-collapse id="collapseTutorias">
          
          <div class="text-center mb-3 mt-3 row">
            <div class="col-md-3"><strong>TEMA</strong></div>
            <div class="col-md-3"><strong>ESTADO</strong></div>
            <div class="col-md-6"><strong>OBSERVACIONES</strong></div>
          </div>
          <DetalleAgendamientoTutoriasItems v-for="(item, index_3) in tutorias" 
          :key="'T'+index_3" :agendamiento="item" :estudiante="estudiante"/>
      </b-collapse>
      </div>
    </div>
  </template>
  
  <script>
    import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
    import DetalleAgendamientoTutoriasItems from '@/components/Estudiantes/DetalleAgendamientoTutoriasItems';
    export default {
      name: 'DetalleAgendamientoTutorias',
      components: {
        DetalleAgendamientoTutoriasItems
      },
      data () {
        return {
        }
      },
      props: {
        estudiante: Object
      },
      mounted () {
        if(this.estudiante.agendamientos_tutorias == undefined){
          console.log('DetalleProgramacionesTutorias undefined')
          this.fetchTutorias(this.estudiante.id)
        } 
        
      },
      methods:{
        ...mapActions({
          fetchTutorias: 'estudiantes/fetchTutorias',
        }),
        ...mapMutations({
          
        })
  
      },
      computed: {
        ...mapState({
          
        }),
        ...mapGetters({
          getTutorias: 'estudiantes/getTutorias'
        }),
        tutorias() {
          return this.getTutorias(this.estudiante.id);
        }
        
      }
    }
  </script>
  
  <style scope>
  
      .programaciones_wrapper .tabla{
        
      }
  </style>