import Vue from 'vue';

const state = {
  lista: [],
  tutores: [],
  detalle : {},
  novedades: [],
  tutores:[],
};

const actions = {
  fetchLista:({commit},data) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.post('docente/lista',data).then(
        response =>{
          commit('setLista',response.data);
          resolve(response.data)
        }
      ).catch(error=>{
        commit('setError', error, { root: true });
        reject(error)
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },
  fetchDetalle:({commit},docente_id) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.post('docente/detalle',{id:docente_id}).then(
        response =>{
          commit('setDetalle',response.data);
          resolve(response.data)
        }
      ).catch(error=>{
        commit('setError', error, { root: true });
        reject(error)
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },
  fetchConsultar:({commit},docente_id) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.post('docente/consultar',{id:docente_id}).then(
        response =>{
          resolve(response.data)
        }
      ).catch(error=>{
        commit('setError', error, { root: true });
        reject(error)
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },
  crear:({commit},data) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.post('docente/crear',data).then(
        response =>{
          resolve(response.data)
        }
      ).catch(error=>{
        commit('setError', error, { root: true });
        reject(error)
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },
  crearMasivo:({commit},data) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.post('docente/crear_masivo',data).then(
        response =>{
          resolve(response.data)
        }
      ).catch(error=>{
        commit('setError', error, { root: true });
        reject(error)
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },
  editar:({commit},data) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.post('docente/editar',data).then(
        response =>{
          resolve(response.data)
        }
      ).catch(error=>{
        commit('setError', error, { root: true });
        reject(error)
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },
  editarDisponibilidad:({commit},data) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.post('docente/editar_disponibilidad',data).then(
        response =>{
          commit('setDisponibilidad',response.data);
          resolve(response.data)
        }
      ).catch(error=>{
        commit('setError', error, { root: true });
        reject(error)
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },
  editarCursos:({commit},data) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.post('docente/editar_cursos',data).then(
        response =>{
          commit('setCursosPermitidos',response.data);
          resolve(response.data)
        }
      ).catch(error=>{
        commit('setError', error, { root: true });
        reject(error)
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },
  editarTipos:({commit},data) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.post('docente/editar_tipos',data).then(
        response =>{
          commit('setTiposPermitidos',response.data);
          resolve(response.data)
        }
      ).catch(error=>{
        commit('setError', error, { root: true });
        reject(error)
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },
  editarSalon:({commit},data) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.post('docente/editar_salon',data).then(
        response =>{
          commit('setSalonPreterminado',response.data);
          resolve(response.data)
        }
      ).catch(error=>{
        commit('setError', error, { root: true });
        reject(error)
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },
  deleteNovedad:({commit},data) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.post('novedad_docente/eliminar',data).then(
        response =>{
          resolve(response.data)
        }
      ).catch(error=>{
        commit('setError', error, { root: true });
        reject(error)
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },
  fetchNovedades:({commit},data) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.post('novedad_docente/consultar',data).then(
        response =>{
          commit('setNovedad',response.data);
          resolve(response.data)
        }
      ).catch(error=>{
        commit('setError', error, { root: true });
        reject(error)
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },
  
  fetchTutores:({commit},data) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.post('docente/tutores',data).then(
        response =>{
          commit('setTutores',response.data);
          resolve(response.data)
        }
      ).catch(response=>{
        commit('setError', response, { root: true });
        reject(response)
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  }
  
};

const getters = {
  getDocentesDisponibles: (state) => (marca_relativa) => {
    return state.lista.filter((element)=>{
      return element.activo == 1 && element.disponibilidad && element.disponibilidad.indexOf(marca_relativa)>=0
    })
  },
  getNovedad: (state) => (id) => {
    return state.novedades.find((element)=>{
      return element.id == id
    })
  },
};

const mutations = {
  setLista: (state, lista) => {
    state.lista = lista;
  },
  setNovedad: (state, lista) => {
    state.novedades = lista;
  },
  setDetalle: (state, detalle) => {
    let id = detalle.id;
    Vue.set(state.detalle, id, detalle)
  },
  incializarDetalle: (state, id) => {
    if(!state.detalle[id]){
      Vue.set(state.detalle, id, null)
    }
  },
  setDisponibilidad: (state, datos) => {
    let id = datos.id;
    state.detalle[id].disponibilidad = datos.disponibilidad
  },
  setCursosPermitidos: (state, datos) => {
    let id = datos.id;
    state.detalle[id].cursos_permitidos = datos.cursos_permitidos
  },
  setTiposPermitidos: (state, datos) => {
    let id = datos.id;
    state.detalle[id].salon_predeterminado = datos.salon_predeterminado
  },
  setSalonPreterminado: (state, datos) => {
    let id = datos.id;
    state.detalle[id].tipos_permitidos = datos.tipos_permitidos
  },
  setTutores : (state, datos) => {
    state.tutores = datos
  },
  restart: (state) => {
    state.lista = []
    state.detalle = {}
  },
  
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
