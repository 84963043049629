<template>
  <div class="container  mt-3">
    <div class="col-lg-6 m-auto">
    	
      <div class="form-horizontal">
        <div class="form-group row" v-if="estudiante && estudiante.inscripciones">
          <label class="col-3 col-sm-4 col-form-label">Curso</label>
          <div class="col-9 col-sm-8">
            <select v-model="curso" class="form-control">
              <option disabled value="">Please select one</option>
              <option v-for="inscripcion in estudiante.inscripciones"
              :value="inscripcion.curso">{{ inscripcion.curso.nombre}}</option>
              
            </select>
          </div>
        </div>
        <div v-else>
          No hay cursos inscritos
        </div>

        <button v-if="procesable" class="btn btn-block btn-danger" @click="procesar">
          Activar Curso
        </button>   
        
      </div>
    </div>
  </div>
</template>

<script>
  import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
  
  export default {
    name: 'DetalleActivarCurso',
    data () {
      return {
        curso:null
      }
    },
    props: {
      estudiante: Object
    },
    mounted () {
      
    },
    methods:{
      ...mapActions({
        activarCurso: 'estudiantes/activarCurso',
        fetchDetalle: 'estudiantes/fetchDetalle',
        fetchAgendamientos: 'estudiantes/fetchAgendamientos',
        setWarning: 'setWarning',
      }),
      ...mapMutations({
        
      }),
      procesar(){
        this.activarCurso({
          id:this.estudiante.id,
          curso_id:this.curso.id,
          
        }).then((apiResponse)=>{
          
          this.limpiar()
          this.setWarning('Curso Activo', { root: true }).then(()=>{
            this.fetchDetalle(this.estudiante.id).then(()=>{
              this.fetchAgendamientos(this.estudiante.id)
              this.$router.push('/estudiante/detalle/'+this.estudiante.id)
            }) 
          })
          
        }).catch((apiResponse)=>{
          console.log("error procesando activar curso")
        })
      },
      limpiar(){
        
      }
    },
    computed:{
      ...mapState({
        
      }),
      ...mapGetters({
        
      }),
      procesable: {
        get () {
          return (this.curso)
        },
        set(value){}
      },
    }
  }
</script>

<style scope>
  .programar_wrapper .form-container{
      
  }
	.programar_wrapper{
    
	}
</style>

