<template>
  <b-container fluid v-if="permiso('235AE1F')">
    <hr>
    <div v-if="docente">
    <h4>Tutoria preasignaciones</h4>	
    	<div v-if="disponibilidad_tutoria">

    		<b-row class="tabla-preasignaciones">
    			<!-- ENTRE SEMANA -->
    			<b-col cols="9">
		    		<b-row>
						<b-col class="celda-head text-center" >Franja</b-col>
						<b-col class="celda-head text-center" v-for="(n, index) in 5" :key="index">
				            {{ disponibilidad_tutoria.dias[n].nombre }}
				        </b-col>
					</b-row>
					<div v-for="hora in disponibilidad_tutoria.dias[1].horas">		
						<b-row class="fila">
							<b-col class="text-center">{{ hora.hora }}</b-col>
							<b-col class="text-center" v-for="(n, index) in 5" :key="index">
            
								<div v-if="disponibilidad.includes(n+'T'+hora.hora)">
	                  <div v-if="getPreasignacion(n,hora.hora)">
	                  	<b-button class="btnFlat m-1" size="sm" variant="light" @click="iniciarEditarPreasignacion(n,hora.hora)">
			                {{getPreasignacionTextoSalon(n,hora.hora)}}<br>
			                  <div v-html="getPreasignacionTextoTipos(n,hora.hora)"></div>
			                </b-button>
	                  </div>
	                  <div v-else>
	                  	<b-button size="sm" variant="primary" @click="iniciarEditarPreasignacion(n,hora.hora)">
			                  ASIGNAR
			                </b-button>
	                  </div>

                </div>
                <div v-else>
                  -
                </div>

    					</b-col>
    				</b-row>
                </div>
          	</b-col>
          	<!-- SABADOS -->
    			<b-col cols="3">
        		<b-row>
    				<b-col class="celda-head text-center" >Franja</b-col>
    				<b-col class="celda-head text-center">
    		            {{ disponibilidad_tutoria.dias[6].nombre }}
    		        </b-col>
    			</b-row>
    			<div v-for="(hora, index_1) in disponibilidad_tutoria.dias[6].horas" :key="index_1">		
    				<b-row class="fila">
    					<b-col class="text-center">{{ hora.hora }}</b-col>
    					<b-col class="text-center">
    						<div v-if="disponibilidad.includes(hora.id)">
                    <div v-if="getPreasignacion(6,hora.hora)">
                      <b-button class="btnFlat m-1" size="sm" variant="light" @click="iniciarEditarPreasignacion(6,hora.hora)">
                      {{getPreasignacionTextoSalon(6,hora.hora)}}<br>
                        <div v-html="getPreasignacionTextoTipos(6,hora.hora)"></div>
                      </b-button>
                    </div>
                    <div v-else>
                      <b-button size="sm" variant="primary" @click="iniciarEditarPreasignacion(6,hora.hora)">
                        ASIGNAR
                      </b-button>
                    </div>
                </div>
                <div v-else>
                  -
                </div>

							</b-col>
						</b-row>
		            </div>
	        	</b-col>
			</b-row>

        </div>
    </div>
    
    <b-button size="sm" variant="info" class="m-1" @click="actualizar">Actualizar</b-button>
    <b-button size="sm" variant="info" class="m-1" @click="iniciarCalcularPreasignaciones">Preasignar Salón</b-button>
    
    
    <!-- CONFIMARCION EDITAR PREASIGNACION -->
    <b-modal  @ok="procesarEditarPreasignacion" @cancel="cancelarEditarPreasignacion" 
	    @hide="cancelarEditarPreasignacion" 
	    no-close-on-backdrop   ok-title="Guardar" cancel-title="Cancelar"
	    v-model="mostrarEditarPreasignacion"   id="modalEditarPreasignacion"
	    title="Editar franja" >
	    <div v-if="preasignacionCandidato.dia">
	    	Dia:{{ disponibilidad_tutoria.dias[preasignacionCandidato.dia].nombre }}	
	    </div>

	    <div v-if="preasignacionCandidato.hora">
	    	Hora:{{ preasignacionCandidato.hora }}
	    </div>
	    
	    
	    Salón:
	    <select @change="cambioSalon" v-if="mostrarEditarPreasignacion" v-model="preasignacionCandidato.salon_id" class="form-control">
          <option selected value="">--SELECCIONE--</option>
          <option value="delete">---SIN SALON---</option>
          <option v-for="(salon, index_2) in salonesLibres"
          :value="salon.id" :key="index_2">{{ salon.nombre}}</option>
        </select>
        <div v-if="getSalonCandidato">
        	Tipos:
        	<b-row>
        		<b-col v-for="(tipo, index_3) in getTipoComunes" :key="index_3">
	        		<b-button class="btnFlat m-1" size="sm" variant="success" v-if="preasignacionCandidato.tipos.tipos.includes(tipo)" @click="quitarTipo(tipo)">
						{{getNombreLargo(tipo)}}
					</b-button>
					<b-button class="btnFlat m-1" size="sm" variant="dark" v-else @click="agregarTipo(tipo)">
						{{getNombreLargo(tipo)}}
					</b-button>
        		</b-col>
        	</b-row>
        </div>
        <div v-if="getSalonCandidato">
	        Combinado:
		    <select @change="cambioModo" v-model="preasignacionCandidato.tipos.modo" class="form-control">
	          <option value="and">SI</option>
	          <option value="or">NO</option>
	        </select>
    	</div>
    	<!-- DEBUG
        <div v-if="getSalonCandidato">
        	salon candidato:
        	{{getSalonCandidato}}
        </div>
        <h5>candidato</h5>
        {{preasignacionCandidato}}
		-->
    </b-modal>

    <!-- CONFIMARCION CALCULAR PREASIGNACIONES-->
    <b-modal  @ok="procesarCalcularPreasignaciones" @cancel="cancelarCalcularPreasignaciones" 
	    @hide="cancelarCalcularPreasignaciones" 
	    no-close-on-backdrop   ok-title="Aceptar" cancel-title="Cancelar"
	    v-model="mostrarCalcularPreasignaciones"   id="modalCalcularPreasignaciones"
	    title="Preasignar salón semanal" >
	    <p>
	    Se recalculará la preasignación semanal del docente con base en los parámetros de disponibilidad, tipos permitidos y los tipos permitidos del salón seleccionado.
	    Tenga en cuenta que se borrará la preasignación que tenga el docente actualmente.
		</p>
	    Seleccione salón predeterminado:
	    <select v-if="mostrarCalcularPreasignaciones" v-model="salonSeleccionado" class="form-control">
          <option selected value="">--SELECCIONE--</option>
          <option value="delete">---SIN SALON---</option>
          <option v-for="(salon, index_4) in salonesPosibles" :key="index_4"
          :value="salon.id">{{ salon.nombre}}</option>
        </select>
	    
    </b-modal>
  </b-container>
</template>

<script>
  import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
  import Vue from 'vue';

  export default {
    name: 'DocentePreasignacionTutoria',
    data () {
      return {
        mostrarEditarPreasignacion: false,
        mostrarCalcularPreasignaciones:false,
        salonSeleccionado: null,
        preasignacionCandidato : {},
        preasignacionCandidatoDefault : {
      		dia: null,
      		hora: null,
      		salon_id: null,
      		docente_id: null,
      		tipos: {
      			tipos: [],
      			modo: 'or'
      		}
      	}
      }
    },
    props: {
      docente: Object
    },
    mounted () {
     	if(this.salones && this.salones.length<=0){
     		this.fetchSalones()
     	}

     	this.actualizar()
    },
    methods:{
      ...mapActions({
        fetchPreasignacionesXDocente: 'preasignaciones/fetchPreasignacionesXDocente',
        calcularPreasignaciones: 'preasignaciones/calcularPreasignaciones',
        guardarPreasignacion: 'preasignaciones/guardarPreasignacion',
        fetchSalones: 'salones/fetchLista',
      }),
      ...mapMutations({
      }),
      
      actualizar(){
        this.fetchPreasignacionesXDocente({docente_id:this.docente.id}).then(() => {
			console.log(this.preasignacionCandidato)
		})
      },
      
      getPreasignacion(dia,hora){
      	if(this.preasignacionesDocente){
      		return this.preasignacionesDocente.find(element=>{
      			return element.dia == dia && element.hora==hora
      		})
      	}
      },

      getPreasignacionTextoSalon(dia,hora){
      	if(this.preasignacionesDocente){
      		let preasignacion = this.getPreasignacion(dia,hora)
      		let texto = preasignacion.salon.nombre
      		return texto
      	}
      	return null
      },

      getPreasignacionTextoTipos(dia,hora){
      	if(this.preasignacionesDocente){
      		let preasignacion = this.getPreasignacion(dia,hora)
      		let texto = ''
      		if(preasignacion.tipos && preasignacion.tipos.tipos){
      			let lista = preasignacion.tipos.tipos.map(element=>{
      				let tipoObj = this.tipos.find(tipo=>{
      					return tipo.id == element
      				})
      				let html = tipoObj?tipoObj.nombre_corto:'indefinido';
      				html = '<div class="badge" style="color:#fff;background-color:#'+tipoObj.color+';">'+html+'</div>';
      				return html
      			})
      			if(preasignacion.tipos.modo && preasignacion.tipos.modo == 'and'){
      				texto = lista.join(' y ')		
      			}else{
      				texto = lista.join('')
      			}
      		}
      		
      		return texto
      	}
      	return null
      },

      iniciarEditarPreasignacion(dia,hora){
      	this.cancelarEditarPreasignacion()

      	let preasignaciones = this.preasignaciones(this.docente.id)
      	let preasignacionPorEditar = preasignaciones.find(element=>{
      		return element.dia == dia && element.hora == hora 
      	})
      	if(preasignacionPorEditar){
      		
      		let salon_id = preasignacionPorEditar.salon_id
      		let tipos = preasignacionPorEditar.tipos.tipos.slice(0)
      		this.preasignacionCandidato = {
	      		id: preasignacionPorEditar.id,
	      		dia: preasignacionPorEditar.dia,
	      		hora: preasignacionPorEditar.hora,
	      		salon_id: preasignacionPorEditar.salon_id,
	      		docente_id: this.docente.id,
	      		tipos: {
	      			tipos: tipos,
	      			modo: preasignacionPorEditar.tipos.modo
	      		}
	      	}
      	}else{
      		this.preasignacionCandidato = {
	      		dia: dia,
	      		hora: hora,
	      		salon_id: null,
	      		docente_id: this.docente.id,
	      		tipos: {
	      			tipos: [],
	      			modo: 'or'
	      		}
	      	}
      	}
      	
      	this.mostrarEditarPreasignacion = true
      },
      procesarEditarPreasignacion(){
		this.guardarPreasignacion(this.preasignacionCandidato)
      },
      cancelarEditarPreasignacion(){
      	this.preasignacionCandidato = {}
      },
      iniciarCalcularPreasignaciones(){
      	this.cancelarCalcularPreasignaciones()
      	this.mostrarCalcularPreasignaciones = true
      },
      procesarCalcularPreasignaciones(){
      	if(this.salonSeleccionado){
      		this.calcularPreasignaciones({docente_id:this.docente.id,salon_id:this.salonSeleccionado})
      	}
      },
      cancelarCalcularPreasignaciones(){
      	this.salonSeleccionado=null
      },
      cambioSalon(){
      	if(this.preasignacionCandidato.tipos){
      		this.preasignacionCandidato.tipos.tipos = []	
      		this.preasignacionCandidato.tipos.modo = 'or'	
      	}
      },
      cambioModo(){
      	if(this.preasignacionCandidato.tipos.modo == 'and'){
      		//VALIDAR COMBINACIONES POSIBLES
      		if(this.preasignacionCandidato.tipos.tipos.length<2){
      			//DEBE SELECCIONAR MINIMO DOS TIPOS
      			this.preasignacionCandidato.tipos.modo = 'or'
      		}else{
      			if(this.esCombinacionValida(this.preasignacionCandidato.tipos.tipos)){
      				this.preasignacionCandidato.tipos.modo = 'and'
      			}else{
      				this.preasignacionCandidato.tipos.modo = 'or'
      			}

      		}
      	}
      },
      agregarTipo(id){
      	this.preasignacionCandidato.tipos.tipos.push(id)
        this.cambioModo()
      },
      quitarTipo(id){
		this.preasignacionCandidato.tipos.tipos.splice(
			this.preasignacionCandidato.tipos.tipos.indexOf(id),1)
		this.cambioModo()
      },
    },
    computed: {
      ...mapState({
        tipos: state => state.asw.tipos, 
        combinacionesPosibles: state => state.asw.combinacionesPosibles, 
        disponibilidad_tutoria: state => state.asw.disponibilidad_tutoria,
        salones: state => state.salones.lista, 
      }),
      ...mapGetters({
        permiso: 'auth/permiso',
        preasignaciones: 'preasignaciones/getPreasignacionesXDocente', 
        getTipoNombreCorto: 'asw/getTipoNombreCorto', 
        getNombreLargo: 'asw/getNombreLargo', 
        esCombinacionValida: 'asw/esCombinacionValida', 
      }),
      disponibilidad(){
      	if(this.docente && this.docente.disponibilidad){
	          return  JSON.parse(this.docente.disponibilidad)
	    } else {
			return [];
		}
      },
      preasignacionesDocente(){
      	if(this.docente){
      		return this.preasignaciones(this.docente.id)	
      	}
      	return null
      	
      },
      salonesPosibles(){
    	   if(this.docente && this.salones.length>0){
    	
    		let salones_posibles = this.salones.filter(salon=>{
              if(salon.activo){
                for(let x in salon.tipos_permitidos){
                  let tipo = salon.tipos_permitidos[x]
                  if(this.docente.tipos_permitidos && this.docente.tipos_permitidos.includes(tipo)){
                    return true
                  }
                }  
              }
	            return false
	          })

	        return salones_posibles
    	   }
        return []
      },

      salonesLibres(){
        
        let libres = this.salonesPosibles.filter(salon=>{
          
          if(salon.preasignaciones){
            let preasignacionCruzada = salon.preasignaciones.find(preasignacion=>{
              return preasignacion.dia == this.preasignacionCandidato.dia 
              && preasignacion.hora == this.preasignacionCandidato.hora
            })
            if(preasignacionCruzada){
              return false
            } 
          }
          return true

        })
        return libres

      },

      getSalonCandidato(){
      	
      	if(this.salones && this.preasignacionCandidato.salon_id != null){
      		return this.salones.find(element=>{
      			return element.id == this.preasignacionCandidato.salon_id
      		})
      	}
      	return null
      },
      getTipoComunes(){
      	let salon = this.getSalonCandidato
      	if(salon && salon.tipos_permitidos && this.docente && this.docente.tipos_permitidos){
      		return salon.tipos_permitidos.filter(tipo=>{
				return this.docente.tipos_permitidos.includes(tipo)
			})
      	}
      	return []
      }

      
    }
  }
</script>

<style scope>
	.btnFlat{
	    font-size: 12px !important;
    	padding: 1px 3px !important;
	}
	.fila{
		border-top: 1px solid #ccc;
	}
	.tabla-preasignaciones .col{
		padding: 0px;
	}
	.tabla-preasignaciones .col button{
		max-width: 140px;
	}
</style>