import Vue from 'vue';

const state = {
  
};

const actions = {

  agendar:({commit},payload) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.post('agendamiento/agendar',payload).then(
        response =>{
          resolve(response.data)
        }
      ).catch(response=>{
        commit('setError', response, { root: true });
        reject(response)
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },
  cancelar:({commit},payload) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.post('agendamiento/cancelar',payload).then(
        response =>{
          resolve(response.data)
        }
      ).catch(error=>{
        commit('setError', error, { root: true });
        reject(error)
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },
  cancelarTutoria:({commit},payload) => {
    commit('startProcessing', null, { root: true });
    return new Promise((resolve, reject) => {
      Vue.http.post('tutorias/cancelar',payload).then(
        response =>{
          resolve(response.data)
        }
      ).catch(error=>{
        commit('setError', error, { root: true });
        reject(error)
      }).finally(()=>{
        commit('stopProcessing', null, { root: true });
      })
    });
  },
  

  
  
};

const getters = {
  
};

const mutations = {
  
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
