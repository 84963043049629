<template>
  <div>
    <div>
      <div class="list-group-item d-flex justify-content-between align-items-center row header-row">
        <div class="col-2">
        </div>
        <div class="col-10">
          <div class="row">
            <div class="col-sm-3" v-for="(grupo, index_4) in grupos[fecha_texto]" :key="index_4">
              {{ grupo.salon }} <br> <a :href="`#/docente/detalle/${ grupo.profesor_id }`" target="_blank">{{ grupo.primer_nombre+" "+grupo.primer_apellido }}</a> 
            </div>
          </div>
        </div>
      </div>
      <b-list-group>
        <b-list-group-item v-for="(hora, index_2) in agenda[fecha_texto].horas" :key="index_2" class="d-flex justify-content-between align-items-center">
          <div class="col-2">
            {{ hora.nombre_corto }} 
          </div>
          <div class="col-10">
            <div class="row">
  
              <div class="col-sm-3" v-for="(grupo, index_5) in grupos[fecha_texto]" :key="index_5">
                <a :href="`#/estudiante/detalle/${ estudianteID(fecha_texto, hora, grupo.salon_id) }`" target="_blank">{{ estudianteNombre(fecha_texto, hora, grupo.salon_id)}}</a>
              </div>
            </div>
          </div>
        </b-list-group-item>
      </b-list-group>
  
      <div >
        
      </div>
      
      <!-- <TutoriaXFecha :activo="tabActivo" :rightPanel="rightPanel"
      v-for="(hora, index_2) in dia.horas" :key="index_2" :hora="hora" :dia="dia"/> -->
    </div>
  </div>
</template>

<script>
  import {mapState, mapActions, mapMutations} from 'vuex';

  export default {
    name: 'TutoriaDetalle',
    components: {
    },
    data () {
      return {
        rightPanel : true,
      }
    },
    props : {
      fecha_texto: String
    },
    mounted () {
      
    },
    methods:{
      ...mapActions({

      }),
      ...mapMutations({

      }),
      salonNombre(clase) {
        return clase.salon.nombre;
      },
      docenteNombre(clase) {
        return clase.profesor.primer_nombre+" "+clase.profesor.primer_apellido;
      },
      estudianteNombre(fecha, hora, salon_id) {
        if (this.tutorias[fecha]) {
          let encontrados = this.tutorias[fecha].filter(x => x.clase.hora == hora.hora); 
          let encontrado = encontrados.find(x => x.clase.salon_id == salon_id);
          if (encontrado) {
            return encontrado.estudiante.primer_nombre+" "+encontrado.estudiante.primer_apellido;
          }
        } 
        return "";
      },
      estudianteID(fecha, hora, salon_id) {
        if (this.tutorias[fecha]) {
          let encontrados = this.tutorias[fecha].filter(x => x.clase.hora == hora.hora); 
          let encontrado = encontrados.find(x => x.clase.salon_id == salon_id);
          if (encontrado) {
            return encontrado.estudiante.id;
          }
        } 
        return "";
      }
    },
    computed: {
      ...mapState({
          tutorias: state => state.tutorias.tutorias,
          agenda: state => state.tutorias.agenda,
          grupos:  state => state.tutorias.grupos_salones
      }),
      
    }
  }
</script>

<style scope>
.derecha{
  padding-bottom: 30px
}
.header-row {
  position: sticky !important;
  top: 0px; /*try more if you have page header/navbar*/ 
  width: 100%;
  z-index: 1;
  background: white;
}
.list-group-item{
  padding: 0.25rem 1.25rem !important;
}

</style>