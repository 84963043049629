<template>
  <div class="p-3" v-if="permiso('BA0052EE')">
  	
	    <h1>Disponibilidad Actual</h1>
	    <div v-if="agenda_disponible">
	    	<div v-for="(dia, index) in agenda_disponible" :key="index">
	    		<h4>{{dia.fecha}} - Total:{{indicadorTotal(dia)}} </h4>
	    		<b-row  v-if="tipos">
	    			<b-col class="celda-head" cols="1">Franja</b-col>
	    			<b-col class="celda-head text-center" v-for="(tipo, t_index) in tipos" :key="t_index">
	    				{{tipo.nombre_corto}}
	    			</b-col>
            <b-col class="celda-head text-center">Total</b-col>
  				</b-row>
  				<div v-if="dia.horas">
  					<div v-for="(hora, h_index) in dia.horas" :key="h_index">
  						<DashboardDisponibilidadFranja :dia="dia" :hora="hora">
  						</DashboardDisponibilidadFranja>
  					</div>
  				</div>
	    		<hr>
	    	</div>
	    </div>
	    <b-button size="sm" variant="link" @click="actualizar">Actualizar</b-button>	
  </div>
</template>


<script>
  import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
	import DashboardDisponibilidadFranja from '@/components/Dashboard/DashboardDisponibilidadFranja';

  export default {
    name: 'DashboardDisponibilidad',
    components:{
    	DashboardDisponibilidadFranja
    },
    data(){
      return {

      }
    },
    mounted () {
    	this.fetchAgendaDisponible()
    },
    methods:{
      ...mapActions({
      	fetchAgendaDisponible: 'asw/fetchAgendaDisponible',
      	fetchDisponibilidad: 'estado/fetchDisponibilidad',
      }),
      ...mapMutations({
      }),
      
      actualizar(){
        console.log(this.agenda_disponible);
      	if(this.agenda_disponible){
      		for(let i in this.agenda_disponible){
      			let dia = this.agenda_disponible[i]
      			for(let j in dia.horas){
      				let hora = dia.horas[j]
      				this.fetchDisponibilidad({dia:dia.fecha,hora:hora.hora})		
      			}
      		}
      	}
      },
      indicadorTotal(dia){
        if(this.agenda_disponible){
          let sumatoriaOcupacion = 0
          let sumatoriaCapacidad = 0
          for(let j in dia.horas){
            let hora = dia.horas[j]
            
            let key = this.user.data.sede_id+':'+dia.fecha+':'+hora.hora
            if(this.disponibilidad[key]){
              let estadoFranja =  this.disponibilidad[key]
              sumatoriaCapacidad += estadoFranja.capacidad_salones_ocupados_franja
              sumatoriaCapacidad += estadoFranja.capacidad_docentes_disponibles_franja
              sumatoriaOcupacion += estadoFranja.ocupacion_salones_ocupados_franja
            }
          }
          let r = sumatoriaCapacidad>0? (sumatoriaOcupacion / sumatoriaCapacidad * 100):0
          r = Math.round(r)
          return sumatoriaOcupacion +'/'+sumatoriaCapacidad+' ('+r+'%)'
        }
      }

    },
    computed:{
      ...mapState({
      	agenda_disponible: state => state.asw.agenda_disponible,
      	tipos: state => state.asw.tipos, 
        user: state => state.auth.user,
        disponibilidad: state => state.estado.disponibilidad,
      }),
      ...mapGetters({   
        permiso: 'auth/permiso',
      }),

    }
  }
</script>

<style scope>
.celda-head{
	font-size: 12px;
	color:#111;
	padding: 0px !important;
    margin: 1px;
    font-weight: bold;
}
	
</style>