
<template>
  <div class="container  mt-3">
    <div class="m-auto">
    	
      <div class="form-horizontal">
        
          <div class="form-group row">
            <label class="col-3 col-sm-4 col-form-label">Sede</label>
            <div class="col-3 col-sm-4">
              <select v-model="sede_id" class="form-control">
                <option disabled value="">Please select one</option>
                <option v-if="opciones" v-for="opcion in opciones" :value="opcion.id">{{ opcion.nombre }}</option>
              </select>
            </div>
            <div class="col-3 col-sm-4">
              <button v-if="procesable" class="btn btn-block btn-danger" @click="procesar">
                Cambiar Sede
              </button>
              <span>Recuerde que los funcionarios de la sede actual perderán visibilidad sobre este estudiante. </span> 
            </div>  
          </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
  import Vue from 'vue';

  export default {
    name: 'DetalleSede',
    components: {
      
    },
    data () {
      return {
        sede_id:null,
      }
    },
    props: {
      estudiante: Object
    },
    mounted () {
      if(!this.opciones || (this.opciones && this.opciones.length==0)){
        this.fetchSedes()
      }
      if(this.estudiante){
        this.sede_id = this.estudiante.sede_id  
      }else{
        console.log('Hay que asegurarse de tener un estudiante antes de utilizar el componente DetalleSede')
      }
    },
    methods:{
      ...mapActions({
        fetchSedes: 'sedes/fetchLista',
        fetchDetalle: 'estudiantes/fetchDetalle',
        setWarning: 'setWarning',
      }),
      ...mapMutations({
        
      }),
      procesar(){
        
        Vue.http.post('user/cambiar_sede',{id:this.estudiante.id,sede_id:this.sede_id})
          .then((response)=>{
             this.limpiar()
	          this.setWarning('Sede cambiada', { root: true }).then(()=>{
	            this.$router.push('/estudiantes')
	          })
          }).catch((response)=>{
            this.setWarning(response.data, { root: true })
          })
      },
      limpiar(){
        this.curso_id = null
      }
    },
    computed:{
      ...mapState({
        opciones: state  => state.sedes.lista,
      }),
      ...mapGetters({
        
      }),
      procesable: {
        get () {
          return (this.sede_id && this.sede_id != this.estudiante.sede_id)
        },
        set(value){}
      },
    }
  }
</script>

<style scope>
  .programar_wrapper .form-container{
      
  }
	.programar_wrapper{
    
	}
</style>

