<template>
  <header>
    <b-navbar toggleable="lg" type="dark" class="pt-0 pb-0 bg-blue">
      <b-navbar-toggle v-if="isLogged" target="nav_collapse"></b-navbar-toggle>
      <b-navbar-brand href="#/"><img style="width:100px" src="../assets/logo_small_white.png"></b-navbar-brand>
      <b-collapse is-nav id="nav_collapse">
        
        <b-navbar-nav>
          <router-link size="sm" v-if="isLogged && permiso('4586B73F')" class="nav-link" to="/estudiantes" >Estudiantes</router-link>
          <router-link size="sm" v-if="isLogged && permiso('8A28EE41')" class="nav-link" to="/docentes" >Docentes</router-link>
          <router-link size="sm" v-if="isLogged && permiso('B2903DC1')" class="nav-link" to="/salones" >Salones</router-link>

          <b-nav-item-dropdown text="Académico">
            <b-dropdown-item v-if="isLogged && permiso('19E70E2C')">
              <router-link  class="dark" to="/cursos" >Cursos</router-link>
            </b-dropdown-item>
            <b-dropdown-item v-if="isLogged && permiso('B7A9DE95')">
              <router-link  class="dark" to="/examenes" >Examenes</router-link>
            </b-dropdown-item>
          </b-nav-item-dropdown>
          
          <b-nav-item-dropdown text="Programador">
          <b-dropdown-item v-if="isLogged && permiso('8D24244B')">
              <router-link  type="dark" to="/programador">Clases</router-link>
          </b-dropdown-item>
          <b-dropdown-item v-if="isLogged && permiso('023AB260')">
            <router-link  type="dark" to="/tutoria">Tutorias</router-link>
        </b-dropdown-item>

          </b-nav-item-dropdown>

          <router-link v-if="isLogged && permiso('293B9B69')" class="nav-link" target="_blank" to="/pantalla" >Pantalla</router-link>
          <router-link v-if="isLogged && permiso('4E621462')" class="nav-link" target="_blank" to="/logistica" >Logistica</router-link>
          
          <b-nav-item-dropdown text="Admin">
            <b-dropdown-item v-if="isLogged && permiso('42A34371')">
              <router-link  type="dark" to="/usuarios" >Usuarios</router-link>
            </b-dropdown-item>
            <b-dropdown-item v-if="isLogged && permiso('4F40D01B')">
              <router-link  type="dark" to="/grupos" >Grupos</router-link>
            </b-dropdown-item>
            <b-dropdown-item v-if="isLogged && permiso('E4D2DDE5')">
              <router-link  type="dark" to="/cierres" >Cierres</router-link>
            </b-dropdown-item>
            <b-dropdown-item v-if="isLogged && permiso('2360680B')">
              <router-link  type="dark" to="/banners/lista" >Banners</router-link>
            </b-dropdown-item >
            <b-dropdown-item v-if="isLogged && permiso('EACA7E4')">
              <router-link  type="dark" to="/masivos"
               >Masivos</router-link>
            </b-dropdown-item>
            <b-dropdown-item v-if="isLogged && permiso('E1D33839')">
              <router-link  type="dark" to="/cacelacionMasiva" >Cancelación masiva</router-link>
            </b-dropdown-item>
          </b-nav-item-dropdown>

          <b-nav-item-dropdown text="Reportes">
            <b-dropdown-item v-if="isLogged && permiso('17737EC6')">
              <router-link  type="dark" to="/reportes/ocupacion" >Ocupacion</router-link>
            </b-dropdown-item>
            <b-dropdown-item v-if="isLogged && permiso('17737EC6')">
              <router-link  type="dark" to="/reportes/actividad" >Actividad</router-link>
            </b-dropdown-item>
            <b-dropdown-item v-if="isLogged && permiso('4F40D01B')">
              <router-link  type="dark" to="/reportes/consumo" >Consumo</router-link>
            </b-dropdown-item>
            <b-dropdown-item v-if="isLogged && permiso('11FC3E77')"> <!--  -->
              <router-link  type="dark" to="/reportes/siet" >SIET</router-link>
            </b-dropdown-item>
            <b-dropdown-item v-if="isLogged && permiso('E96EB087')"> <!--  -->
              <router-link  type="dark" to="/reportes/solicitud-avance" >Solicitud Avance</router-link>
            </b-dropdown-item>
            <b-dropdown-item v-if="isLogged && permiso('F83DCEB2')"> <!--  -->
              <router-link  type="dark" to="/reportes/encuesta-satisfaccion" >Encuesta Satisfacción</router-link>
            </b-dropdown-item>
            
            
          </b-nav-item-dropdown>
          
        </b-navbar-nav>


        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <router-link v-if="isLogged" class="nav-link" to="/profile" >{{textoMiCuenta}}</router-link>
          <a href="#" v-if="isLogged" class="nav-link"  @click.prevent="processLogout()">Salir</a>
        </b-navbar-nav>

      </b-collapse>
    </b-navbar>

    
  </header>
</template>

<script>
  import {mapState, mapGetters, mapActions} from 'vuex';
  
  export default {
    components: {},
    name: 'navigation',
    mounted(){
      if(this.user && !this.sede){
        this.fetchSede()    
      }
      
    },
    methods: {
      ...mapActions({
        logout: 'auth/logout',
        fetchSede: 'auth/fetchSede'
      }),
      processLogout () {
        this.logout().then(
          this.$router.push('/login')
        )
      }
    },
    computed:{
    ...mapState({
        isLogged: state => state.auth.logged,
        user: state => state.auth.user,
        sede: state => state.auth.sede
      }),
    ...mapGetters({
        permiso: 'auth/permiso', 
      }),
      textoMiCuenta(){
        let texto =  this.user.data.primer_nombre+' '+this.user.data.primer_apellido
        if(this.sede){
          texto = texto +' en '+this.sede.nombre
        }
        return texto
      }
    }
  }
</script>
