<template>
  <div class="">
    <b-navbar toggleable="md" type="dark" variant="info" class="pt-0 pb-0">
      <div class="container">
        <b-navbar-toggle target="nav_collapse_salones"></b-navbar-toggle>
        <h1 v-if="salon" class="text-center navbar-brand pt-0 pb-0 mb-0">{{ salon.nombre }}</h1>
        <b-collapse is-nav id="nav_collapse_salones">
          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto">
            <a :href="`#/salones`" class="nav-link" title="Lista">Lista</a>
            <a v-if="salon && permiso('EE7EF073')" :href="`#/salon/editar/${ salon.id }`" class="nav-link" title="Ver">Editar</a>
            <a v-if="salon" href="#" class="nav-link"  @click="actualizar" title="Actualizar">Actualizar</a>
          </b-navbar-nav>

        </b-collapse>
      </div>
    </b-navbar>
    <div class="container pt-3 m-auto text-left ">
      
      <div v-if="salon">
        <h2>Datos básicos</h2>
        
        <div class="row">
          <div class="col-3"><strong>Nombre:</strong>
          </div><div class="col-9">{{ salon.nombre }}</div>
        </div>
        <!--
        <div class="row">
          <div class="col-3"><strong>Numero:</strong>
          </div><div class="col-9">{{ salon.numero }}</div>
        </div>
      -->
        <div class="row">
          <div class="col-3"><strong>Capacidad:</strong></div>
          <div class="col-9">{{ salon.capacidad }}</div>
        </div>
        
        <div class="row">
          <div class="col-3"><strong>Activo:</strong></div>
          <div class="col-9">{{ salon.activo }}</div>
        </div>
        <!--
        <div class="row">
          <div class="col-3"><strong>Tipo Predeterminado:</strong></div>
          <div class="col-9">{{ tipoSalonTexto }}</div>
        </div>
      -->
        <div class="row">
          <div class="col-3"><strong>Tipos Permitidos:</strong></div>
          <div class="col-9">{{ tiposTexto }}</div>
        </div>
        <div class="row">
          <div class="col-3"><strong>Url:</strong></div>
          <div class="col-9">{{ salon.url }}</div>
        </div>
        
        <SalonPreasignacion v-if="!tieneTutoria" :salon="salon"></SalonPreasignacion>
        <SalonTutoriaPreasignacion v-else :salon="salon"></SalonTutoriaPreasignacion>
    

      </div>
      <div v-else>
        No hay información
      </div>

    </div>
  </div>
</template>

<script>
  import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
  import SalonPreasignacion from '@/components/Salones/SalonPreasignacion';
  import SalonTutoriaPreasignacion from '@/components/Salones/SalonTutoriaPreasignacion';
  

  export default {
    name: 'SalonDetalle',
    components: {
      SalonPreasignacion,
      SalonTutoriaPreasignacion
    },
    data () {
      return {
          agendando: false,
          salon_id: null,
      }
    },
    beforeCreate() {
      this.$store.commit('salones/incializarDetalle',this.$route.params.id)
    },
    
    mounted () {
      this.salon_id = this.$route.params.id;
      if(!this.detalle[this.salon_id]){
        console.log('Detalle mounted actualizar')
        this.actualizar()
      }
    },
    
    methods:{
      ...mapActions({
        fetchDetalle: 'salones/fetchDetalle',
      }),
      ...mapMutations({
        setDetalle: 'salones/setDetalle',
        incializarDetalle: 'salones/incializarDetalle',
      }),
      actualizar(){
        this.fetchDetalle(this.salon_id).then(()=>{
          
        })
      },
      
    },
    computed:{
      ...mapState({
        detalle: state => state.salones.detalle, 
        tipos: state => state.asw.tipos, 
      }),
      ...mapGetters({
        permiso: 'auth/permiso', 
      }),
      salon(){
        return this.detalle[this.salon_id]
      }, 
      tipoSalonTexto(){
        let tipoObj = this.tipos.find((element)=>{
          return element.id == this.salon.tipo_salon  
        })
        if(tipoObj){
          return tipoObj.nombre  
        }else{
          return 'CONFIGURAR'
        }
        
      },
      tiposTexto(){
        if(this.salon.tipos_permitidos && this.salon.tipos_permitidos.length > 0 && this.tipos){
            let tiposObj = this.tipos.filter((element)=>{
              return this.salon.tipos_permitidos.includes(element.id)  
            })
            let texto = ''
            for(let i in tiposObj){
              let tipoObj = tiposObj[i]
              texto += tipoObj.nombre +', '
            }
            return texto
        }else{
          return ''
        }
        
      },
      tieneTutoria(){
        if(this.salon.tipos_permitidos && this.salon.tipos_permitidos.length > 0 && this.tipos){
           var tiene = this.salon.tipos_permitidos.find(x => x == 'tutoria') ? true :  false;
          return tiene;
        }else{
          return false
        }
      }
    }
    
  }
</script>

<style scope>

  .perfil_wrapper .container{
      background-color: #fff;
      padding: 15px;
  }
</style>