<template>
  <div class="">
    <b-navbar toggleable="md" type="dark" variant="info" class="pt-0 pb-0">
      <div class="container">
        <b-navbar-toggle target="nav_collapse_docentes"></b-navbar-toggle>
        <h1 v-if="docente" class="text-center navbar-brand pt-0 pb-0 mb-0">Editar Docente</h1>
        <b-collapse is-nav id="nav_collapse_docentes">
          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto">
            <b-nav-item  href="#" @click="procesarCancelar">Cancelar</b-nav-item>
          </b-navbar-nav>

        </b-collapse>
      </div>
    </b-navbar>
    <div class="container pt-3 m-auto text-left ">
       <h2>Datos básicos</h2>
      <form
        autocomplete="off"
        class="form-horizontal"
        @submit.prevent="validateBeforeSubmit"
      >
        <div v-if="docente">
          <InputTextSimple titulo="Primer Nombre" :modelo="docente.primer_nombre" 
            @change="docente.primer_nombre = $event"/>
          <InputTextSimple titulo="Segundo Nombre" :modelo="docente.segundo_nombre" 
            @change="docente.segundo_nombre = $event"/>
          <InputTextSimple titulo="Primer Apellido" :modelo="docente.primer_apellido" 
            @change="docente.primer_apellido = $event"/>
          <InputTextSimple titulo="Segundo Apellido" :modelo="docente.segundo_apellido" 
            @change="docente.segundo_apellido = $event"/>
          <SelectTipoDocumento titulo="Tipo de Documento" :modelo="docente.tipo_documento" 
            @change="docente.tipo_documento=$event"/>
          <InputNumeroDocumento titulo="Número de Documento" :modelo="docente.numero_documento" 
            @change="docente.numero_documento=$event"/>
          <InputEmailSimple titulo="Email" :modelo="docente.email" 
            @change="docente.email=$event"/>
          <InputMovil titulo="Telefono" :modelo="docente.telefono" 
            @change="docente.telefono=$event"/>
          <InputMovil titulo="Movil" :modelo="docente.movil" 
            @change="docente.movil=$event"/>
          <SelectActivo titulo="Activo" :modelo="docente.activo ? docente.activo.toString() : '0'" 
            @change="docente.activo=$event"/>
          
          <div class="form-group row">
            <label class="col-3 col-form-label">Tutor</label>
            <div class="col-9">
              <select v-model="docente.tutor" class="form-control">
                <option disabled value="">Please select one</option>
                <option value="1">Si</option>
                <option value="0">No</option>
              </select>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-3 col-form-label">Split</label>
            <div class="col-9">
              <select v-model="docente.split" class="form-control">
                <option disabled value="">Please select one</option>
                <option value="1">Si</option>
                <option value="0">No</option>
              </select>
            </div>
          </div>


        </div>
        <button class="btn btn-block btn-danger" type="submit">Guardar</button>
      </form>
      <div v-if="docente">
        <hr>
        <h2>Disponibilidad</h2>
        <DocenteEditarDisponibilidad v-if="docente.tutor != '1'" :docente="docente"></DocenteEditarDisponibilidad>
        <DocenteEditarTutoriaDisponibilidad v-else :docente="docente"></DocenteEditarTutoriaDisponibilidad>
        <hr>
        <!--
        <h2>Cursos Permitidos</h2>
        <DocenteEditarCursos :docente="docente"></DocenteEditarCursos>
        <hr>
        -->
        <h2>Tipos Permitidos</h2>
        <DocenteEditarTipos :docente="docente"></DocenteEditarTipos>
        <hr>
        <!--
        <h2>Salón Predeterminado</h2>
        <DocenteEditarSalon :docente="docente"></DocenteEditarSalon>
        -->
      </div>
    </div>
  </div>
</template>

<script>
  import {mapState, mapActions, mapMutations} from 'vuex';
  import DocenteEditarDisponibilidad from '@/components/Docentes/DocenteEditarDisponibilidad';
  import DocenteEditarTutoriaDisponibilidad from '@/components/Docentes/DocenteEditarTutoriaDisponibilidad';
  
  import DocenteEditarCursos from '@/components/Docentes/DocenteEditarCursos';
  import DocenteEditarSalon from '@/components/Docentes/DocenteEditarSalon';
  import DocenteEditarTipos from '@/components/Docentes/DocenteEditarTipos';

  export default {
    name: 'DocenteEditar',
    components: {
      DocenteEditarDisponibilidad,
      DocenteEditarTutoriaDisponibilidad,
      DocenteEditarCursos,
      DocenteEditarSalon,
      DocenteEditarTipos,
    },
    data () {
      return {
           docente_id: null,
      }
    },
    mounted () {
     this.docente_id = this.$route.params.id;
      if(!this.detalle[this.docente_id]){
        this.actualizar()
      }
    },
    
    methods:{
      ...mapActions({
        fetchDetalle: 'docentes/fetchDetalle',
        editar: 'docentes/editar',
        setWarning: 'setWarning',
        setDisponibilidad: 'docentes/setDisponibilidad',
        setCursosPermitidos: 'docentes/setCursosPermitidos',
        setTiposPermitidos: 'docentes/setTiposPermitidos',
        setSalonPreterminado: 'docentes/setSalonPreterminado',
        fetchLista: 'docentes/fetchLista',
      }),
      ...mapMutations({
        
      }),
      procesarCancelar(){
        this.fetchDetalle(this.docente_id).then(()=>{
          this.$router.push('/docente/detalle/'+this.docente_id)
        })
      },
      actualizar(){
        this.fetchDetalle(this.docente_id)
      },
      validateBeforeSubmit () {
        this.$validator.validateAll().then(result => {
          console.log('validateBeforeSubmit')
          if ( ! result) {
            console.log('validateBeforeSubmit error')
            //hay errores
          } else {
            console.log('validateBeforeSubmit no error')
            this.editar(this.docente).then(()=>{
                this.setWarning('Docente Actualizado', { root: true }).then(()=>{
                this.fetchDetalle(this.docente_id).then(()=>{
                  this.fetchLista()
                  this.$router.push('/docente/detalle/'+this.docente_id)

                })
              })
            }).catch(error=>{
              //todo probar si se puede quitar
            })
            
          }
        })
      },

    },
    computed:{
      ...mapState({
        detalle: state => state.docentes.detalle, 
        warning: state => state.warning
      }),
      docente(){
        return this.detalle[this.docente_id]
      },
      
      
    }
    
  }
</script>

<style scope>

  .perfil_wrapper .container{
      background-color: #fff;
      padding: 15px;
  }
</style>