<template>
  <div>
     <b-navbar toggleable="md" type="dark" variant="info" class="pt-0 pb-0">
      <div class="container">
        <b-navbar-toggle target="nav_collapse_estudiantes"></b-navbar-toggle>
        <h1 v-if="progreso" class="text-center navbar-brand pt-0 pb-0 mb-0">{{ progreso.primer_nombre }} {{ progreso.primer_apellido }}</h1>
        <b-collapse is-nav id="nav_collapse_estudiantes">
          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto">
            <a v-if="progreso" :href="`#/estudiante/detalle/${ progreso.id }`" class="nav-link" title="Ver">Volver</a>
            <b-nav-item  v-if="permiso('C3D1A230')"  @click="iniciarDefinirTema">Definir Tema</b-nav-item>
            <b-nav-item  v-if="permiso('4DE6952E')" @click="reiniciarSeccion=true">Reiniciar</b-nav-item>
            <b-nav-item  href="#" @click="recargar">Actualizar</b-nav-item>
          </b-navbar-nav>
        <!--v-if="permiso('4DE6952E')"-->
        </b-collapse>
      </div>
    </b-navbar>
    <div class="container mt-3">
      <div v-if="progreso">
        <h3 class="text-center">Registro Académico</h3>
      </div>
      
      <div v-if="progreso">
        <b-row class="text-center mb-3 mt-3 ">
          <b-col cols="1" class="col-md-1"><strong>ID</strong></b-col>
          <b-col cols="3" class="col-md-3"><strong>CLASE</strong></b-col>
          <b-col cols="2" class="col-md-2"><strong>ESTADO</strong></b-col>
          <b-col cols="2" class="col-md-2"><strong>NOTA</strong></b-col>
          <b-col cols="4" class="col-md-4"><strong></strong></b-col>
        </b-row>
        <ProgresoRegistro v-for="(progreso_fila, index) in progreso.progresos" 
        :progreso_fila="progreso_fila" :consecutivo="index" :key="index" :nota_editable="progreso.avance_nivel == null ? true : false"/>
      </div>
      
      <div class="mt-1">
        <b-row>
          <b-col cols="8">
            <div>
              <strong>NOTA FINAL ACUMULADA: {{notaFinal}}/5.0</strong>
            </div>
    
            <div v-if="permiso('524A95EB')">
              <div v-if="nivelar.edicion">
                <!-- <div class="col-md-3"> -->
                  <strong >NIVELACIÓN: </strong>
                <!-- </div>
                <div class="col-md-4"> -->
                  <input type="text"
                  v-model="nivelar.neditada"
                  name="Nota"
                  placeholder="0"
                  class="form-control form-control-sm input-nota"
                  size="0.01">
                <!-- </div>
                <div class="col-md-2"> -->
                  <strong class="m-3">{{instruccionesNota}}</strong>
                <!-- </div>
                <div class="col-md-3"> -->
                  <b-button @click="cancelarNivelacion" size="sm" variant="danger" >Cancelar</b-button>
                <b-button v-if="puedeEditarNivelacion" @click="procesarNivelacion" size="sm" variant="success" class="ml-3" >Guardar</b-button>
                <!-- </div> -->
    
              </div>
              <div v-else>
                <strong>NIVELACIÓN: {{nivelar.nota}}</strong>  
              </div> 
            </div>
            <div v-if="nivelar.nota">
              <strong>NOTA FINAL: {{nivelar.nota_final}}</strong>  
            </div>
            <div>
              <strong>CLASES: {{progreso && progreso.resumen ? progreso.resumen.clases : '-'}} - {{progreso && progreso.resumen && progreso.resumen.clases ? Math.round((progreso.resumen.clases / progreso.resumen.total)*100, 1) : '-'}}%</strong>
            </div>
            <div>
              <strong>INASISTENCIAS: {{progreso && progreso.resumen ? progreso.resumen.inasistencias : '-'}} - {{progreso && progreso.resumen && progreso.resumen.clases ? Math.round((progreso.resumen.inasistencias / progreso.resumen.total)*100, 1) : '-'}}%</strong>
            </div>
          </b-col>
          <b-col cols="4">
            <ProgresoObservaciones  :curso_id="curso_id" :estudiante_id="estudiante_id" :key="curso_id"/>
          </b-col>
        </b-row>
        
      </div>
      <button v-if="permiso('E4E63F5F')" class="btn btn-info btn-sm ml-2" @click="revisar">Revisar</button>
      <button v-if="permiso('524A95EB') && puedeEditarNivelacion" class="btn btn-info btn-sm ml-2" @click="notaNivelacion">Nivelación</button>
      <button v-if="permiso('6B04B5EF')" class="btn btn-info btn-sm ml-2" @click="notaMostrar">Achievements</button>
      
      <hr>
      <div v-if="niveles_cursados && niveles_cursados.length>0">
        <h4 class="text-center mb-3 mt-3 " >Otros Niveles Cursados</h4>
        <div v-for="(nivel,idx) in niveles_cursados" :key="idx">  
          <h5>{{nivel.nombre}}</h5>
          <div v-if="niveles_avance[nivel.curso_id]">
            <b-row class="text-center mb-3 mt-3 ">
              <b-col cols="1" class="col-md-1"><strong>ID</strong></b-col>
              <b-col cols="3" class="col-md-3"><strong>CLASE</strong></b-col>
              <b-col cols="2" class="col-md-2"><strong>ESTADO</strong></b-col>
              <b-col cols="2" class="col-md-2"><strong>NOTA</strong></b-col>
              DOCENTE
              <b-col cols="4" class="col-md-4"><strong></strong></b-col>
            </b-row>
            <b-row class="text-center mb-3 mt-3 " v-for="(fila,index) in niveles_avance[nivel.curso_id]" :key="index">
              <b-col cols="1" class="text-left col-md-1">{{index+1}}</b-col>
              <b-col cols="3" class="text-left col-md-3">{{fila.nombre}}</b-col>
              <b-col cols="2" class="col-md-2">{{fila.estado}}</b-col>
              <b-col cols="2" class="col-md-2">{{fila.nota}}</b-col>
              <b-co> <p>
                {{docent(fila)}} <br>
                <span style="font-size: 9px;">
                  <span>{{dateFormat(fila.asistencia_fecha)}}</span> &nbsp;
                  {{fila.salon != null ? ubicacion(fila.salon, fila.clase_fecha ):""}}
                </span>
              </p>
            </b-co>
            </b-row>
            <b-row class="mt-1">
              <b-col v-if="notas_avance[nivel.curso_id] && notas_avance[nivel.curso_id].nota_nivelacion">
                <strong>NOTA: {{notas_avance[nivel.curso_id] && notas_avance[nivel.curso_id].nota ? notas_avance[nivel.curso_id].nota : '-'}}/5.0</strong> <br>
                <strong>NOTA NIVELACION: {{notas_avance[nivel.curso_id].nota_nivelacion}}/5.0</strong> <br>
                <strong>NOTA FINAL: {{notas_avance[nivel.curso_id].nota_final}}/5.0</strong>
              </b-col>
              <b-col v-else>
                <strong>NOTA FINAL: {{notas_avance[nivel.curso_id] && notas_avance[nivel.curso_id].nota ? notas_avance[nivel.curso_id].nota : '-'}}/5.0</strong> <br>
              </b-col>
              <b-col>
                <ProgresoObservaciones  :curso_id="nivel.curso_id" :estudiante_id="estudiante_id" :key="nivel.curso_id"/> 
              </b-col>
              
            </b-row>
          </div>
          <b-button v-else class="m-0 p-0" @click="cargarNivel(nivel.curso_id)" size="sm" variant="link" >Ver más</b-button>
          <hr>
        </div>
      </div>
    </div>
    <b-modal no-close-on-backdrop hide-header-close @cancel="cancelarDefinirTema"
    @ok="procesarDefinirTema" v-model="definiendoTema" id="modalDefiniendoTema" 
    :ok-disabled="temaParaDefinir && motivoDefinirTema.length<motivoDefinirTemaMin">
      Seleccione último tema visto por el estudiante.
      <div v-if="parcelador">
        <b-form-select v-model="temaParaDefinir" :options="opcionesParaDefinirTema" class="mb-3" size="sm" />

      </div>
      <div class="mb-2" v-if="temaParaDefinir">
          <b-form-textarea
            id="textarea"
            v-model="motivoDefinirTema"
            placeholder="Cuéntanos, por qué es necesario hacer este proceso manual?"
            rows="3"
            max-rows="6"
          ></b-form-textarea>
          <pre class="d-block text-right">{{ motivoDefinirTema.length }}/{{ motivoDefinirTemaMin }}</pre>
        </div>
    </b-modal>
    <!--modal reinicio-->
     <b-modal no-close-on-backdrop hide-header-close @cancel="cancelarReinicio"
     @ok="procesarReinicio" v-model="reiniciarSeccion"  id="modalReiniciarSeccion"  
     :ok-disabled="reinicioParaDefinir && reinicioDefinir.length<reinicioDefinirMin"
      title="Reiniciar">

      <h5> ¿Estas seguro que desea reiniciar el nivel actual?</h5>
      <!--v-if=""-->
     <div class="mb-2">
      <b-form-textarea
          id="textarea1"
          v-model="reinicioDefinir"
          placeholder="Cuéntanos, por qué es necesario reiniciar?"
          rows="3"
          max-rows="6"
        ></b-form-textarea>
        <pre class="d-block text-right">{{ reinicioDefinir.length }}/{{ reinicioDefinirMin }}</pre>
         </div>

     </b-modal>

     <b-modal size="lg" @ok="procesarNotas"  @cancel="cancelarNotas" v-model="showNotas"  id="modalNotas"
      :ok-disabled="!puedeProcesarNotas"  :title="estudiante && estudiante.estudiante ? estudiante.estudiante.primer_nombre+' '+estudiante.estudiante.segundo_nombre+' '+estudiante.estudiante.primer_apellido+' '+estudiante.estudiante.segundo_apellido : ''">

      <div class="mb-2" >

        <div >
          <b-table-simple hover small caption-top responsive>
            <b-thead head-variant="light"> <!---->
              <b-th>Tema</b-th>
              <b-th>Nota</b-th>
              <b-th>Valor</b-th>
            </b-thead>
            <b-tbody>
              <b-tr v-for="tema in notasTemas" :key="tema.id">
                <b-td>{{tema.nombre}}</b-td>
                <b-td>
                <input type="number"
                v-model="tema.nota"
                :max="tema.rango_nota"
                step="0.01"
                @keyup="maxValue(tema)"
                :disabled="'autonomo' != tema.tipo"
                class="form-control">
                </b-td>
                <b-td>{{((((tema.nota / tema.rango_nota) * tema.peso_nota)*5)/100).toFixed(3) }}</b-td>
              </b-tr>
              <b-tr>
                <b-td colspan="2">NOTA FINAL</b-td>
                <b-td>{{ notaFinalTemp }}/5.0</b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </div>
      </div>

    </b-modal>
    
    <!---->

     <ProgresoReinicio></ProgresoReinicio>
    
  </div>
</template>

<script>
import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';
import ProgresoRegistro from '@/components/Estudiantes/Progreso/ProgresoRegistro';
import ProgresoReinicio from '@/components/Estudiantes/Progreso/ProgresoReinicio.vue';
import ProgresoObservaciones from '@/components/Estudiantes/Progreso/ProgresoObservaciones.vue';

import Vue from 'vue';

export default {
    name: 'Progreso',
    components: {
        ProgresoRegistro,
        ProgresoReinicio,
        ProgresoObservaciones
    },
    data () {
        return {
            estudiante_id:null,
            curso_id: null,
            definiendoTema:false,
            parcelador:null,
            temaParaDefinir: null,
            reinicioParaDefinir: null,
            niveles_cursados: null,
            niveles_avance: {},
            notas_avance: {},
            motivoDefinirTema: '',
            motivoDefinirTemaMin: 40,
            reinicioDefinir: '',
            reinicioDefinirMin: 40,
            reiniciarSeccion: false,
            nivelar:{
                nota:null,
                edicion:false,
                neditada:null,
                editar:true,
                rango_nota:0
            },
            showNotas:false,
            notasTemas:[],
            vista_ligera:false
        }
    },
    props: {
    },
    mounted () {
        this.estudiante_id = this.$route.params.id;
        this.fetchProgreso({estudiante_id:this.estudiante_id})
        this.verNivelesCursados()
        this.consultarEstadoCurso();
    },
    methods:{
        ...mapActions({
            fetchProgreso: 'progresos/fetchProgreso',
            revisarProgreso: 'progresos/revisarProgreso',
            fetchEstadoCurso: 'progresos/estadoCurso',
            fetchNotas: 'progresos/fetchNotas',
            actualizarNotas: 'progresos/actualizarNotas',
            actualizarNivelacion: 'progresos/actualizarNivelacion',
            setWarning: 'setWarning',
        }),
        ...mapMutations({
            startProcessing: 'startProcessing',
            stopProcessing: 'stopProcessing'
        }),
        iniciarDefinirTema(){
            this.definiendoTema=true
            if(this.parcelador==null){
              Vue.http.post('estudiante/parcelador',{estudiante_id:this.estudiante_id}).then(
                  response =>{
                      this.parcelador = response.data
                  }
              ).catch(response=>{
                  console.log(response)
              })
            }
        },
        verNivelesCursados(){
            Vue.http.post('estudiante/niveles_cursados',{estudiante_id:this.estudiante_id}).then(
                response =>{
                  this.niveles_cursados = response.data
                }
            ).catch(response=>{
                console.log(response)
            })
        },
        cargarNivel(id){
            Vue.http.post('estudiante/progreso_otro_nivel',{estudiante_id:this.estudiante_id,curso_id:id}).then(
                response =>{
                  Vue.set(this.niveles_avance, response.data.curso_id, response.data.registros, response.data.nota)
                  Vue.set(this.notas_avance, response.data.curso_id, response.data.nota)
                }
            ).catch(response=>{
                console.log(response)
            })
        },
        procesarDefinirTema(){
          this.startProcessing()
          let payload = {
              estudiante_id:this.estudiante_id,
              tema_id:this.temaParaDefinir,
              motivo: this.motivoDefinirTema,
              
            }
            Vue.http.post('progreso/definir_tema',payload).then(response =>{
                console.log(response.data)
            }).catch(response=>{
                console.log(response)
            }).finally(()=>{
                this.definiendoTema=false
                this.stopProcessing()
                this.cancelarDefinirTema()
                this.recargar()
            })
        },
        procesarReinicio(){
            this.startProcessing()
            // this.procesado = true
            let reinicioN = {
                estudiante_id:this.estudiante_id,  
                obs_reinicio:this.reinicioDefinir
            }
            Vue.http.post('progreso/reiniciar_nivel', reinicioN).then()
            .catch(error=>{
                this.setWarning(error.data.error)
            }).finally(()=>{
                this.stopProcessing()
                this.cancelarReinicio()
                this.recargar()
            })
        },
        cancelarDefinirTema(){
            this.temaParaDefinir = null
            this.motivoDefinirTema = ''
        },
        cancelarReinicio(){
            this.reinicioParaDefinir = null
            this.reinicioDefinir = ''
        },
        recargar(){
            this.fetchProgreso({estudiante_id:this.estudiante_id})
            this.consultarEstadoCurso();
        },
        revisar(){
            this.revisarProgreso({estudiante_id:this.estudiante_id}).then(()=>{
                this.recargar()
            })
        },
        consultarEstadoCurso(){
            this.fetchEstadoCurso({estudiante_id:this.estudiante_id}).then((result)=>{
              this.curso_id = result.curso_id;
              if (result.dato && result.dato.nota_nivelacion) {
                this.nivelar.nota = result.dato.nota_nivelacion;
                this.nivelar.nota_final = result.dato.nota_final;
                this.nivelar.editar = false;
              }
                if (result.codigo == 'nivelar') {
                  this.nivelar.id = result.dato ? result.dato.id : null;
                  this.nivelar.editar = true;
                  this.nivelar.rango_nota = result.rango_nota;
                }
            })
        },
        notaNivelacion() {
          this.nivelar.edicion = true;
        },
        cancelarNivelacion() {
          this.nivelar.edicion=false;
          this.nivelar.neditada=null;
        },
        procesarNivelacion() {
          var data = {estudiante_id:this.estudiante_id,nota:this.nivelar.neditada, registro_id:this.nivelar.id}
          this.actualizarNivelacion(data).then((data) => {
            console.log(data);
              if (data.mensaje) {
                  this.$bvToast.toast(data.mensaje, {
                      title: 'Proceso exitoso',
                      autoHideDelay: 5000
                  })
              }
              if (data.dato) {
                this.nivelar.nota = data.dato.nota_nivelacion;
                this.nivelar.nota_final = data.dato.nota_final;
                this.nivelar.editar = false;
                this.cancelarNivelacion();
              }
              this.revisar();
          }).catch((error) => {
              console.error(error)
          })

        },
        convertirJson(json){
            if(json.estructura != null)
                json.estructura = JSON.parse(json.estructura);
            if(json.notas != null)
                json.notas = JSON.parse(json.notas);
            return json
        },
        notaNivel(registros){
            if(registros){
                let temasConNotas = registros.filter(element=>{
                    return element.rango_nota && element.peso_nota
                })
                let notaF = 0;
                for (let i in temasConNotas){
                    let tema = temasConNotas[i]
                    let notaParcial = (tema.nota / tema.rango_nota) * tema.peso_nota
                    notaParcial = notaParcial.toPrecision(3)*1
                    notaParcial = notaParcial*5/100
                    notaF += notaParcial
                }
                return notaF.toPrecision(3);  
            }
            return null
        }, 
        notaMostrar() {
          this.consultarNotas(this.estudiante_id)
        },
        consultarNotas(estudiante_id) {
            this.fetchNotas({estudiante_id:estudiante_id}).then((result) => {
                this.showNotas = true;
                this.notasTemas = result;
            }).catch((error) => {
                console.error(error)
            })
        },
        procesarNotas() {
            var data = {estudiante_id:this.estudiante_id,temas:this.notasTemas}
            this.actualizarNotas(data).then(() => {
                if (data.mensaje) {
                    this.$bvToast.toast(data.mensaje, {
                        title: 'Proceso exitoso',
                        autoHideDelay: 5000
                    })
                }
                this.revisar();
            }).catch((error) => {
                console.error(error)
            })
        },
        cancelarNotas() {
          console.log("cancelar");
        },
        maxValue(tema) {
            if (tema.nota > tema.rango_nota) {
                tema.nota = tema.rango_nota;
            }
        },
        ubicacion(salon, fecha){
            var fec = ""
            var ho = "";
            var sede = ""
            if(fecha != null && fecha != ""){
                fec = fecha.split('T')[0]
                ho = new Date(fecha);
                fecha = fec.split("-")[2] + "/"+ fec.split("-")[1] + "/" + fec.split("-")[0] + " " + ho.getHours()+":"+ho.getMinutes()+":"+ho.getSeconds();
            }else{
              fecha=""
            }
            if(salon!=null)
                sede = JSON.parse(salon).sede
            else
                sede = ""
            return  sede +" "+ fecha;
        },
        docent(progreso){
            if(progreso && progreso.docente){
                if(progreso.docente != null){
                    var docente = JSON.parse(progreso.docente)
                    return docente.nombre
                }else{return "";}
            }
            else{return "";}
        },
        dateFormat(date) {
          return this.$moment(date).format("DD/MM/YYYY HH:mm:ss");
        }
        
    },
    computed: {
        ...mapState({
          progresos: state => state.progresos.progresos,
          detalle: state => state.estudiantes.detalle, 
        }),
        ...mapGetters({
            getProgreso: 'progresos/getProgreso', 
            permiso: 'auth/permiso', 
        }),
        estudiante(){
            return this.detalle[this.estudiante_id]
        }, 
        progreso(){
            if(this.estudiante_id){
                return this.getProgreso(this.estudiante_id)
            }
            return null
        },
        texto_estudiante(){
            let texto = ''
            if(this.progreso){
                texto += this.progreso.primer_nombre?this.progreso.primer_nombre:''
                texto += this.progreso.segundo_nombre?' '+this.progreso.segundo_nombre:''
                texto += this.progreso.primer_apellido?' '+this.progreso.primer_apellido:''
                texto += this.progreso.segundo_apellido?' '+this.progreso.segundo_apellido:''
            }
            return texto
        },
        puedeEditarNivelacion() {
          return this.nivelar.editar;
            // if(this.nivelar.nota && this.nivelar.nota >= 0 
            //   && this.nivelar.nota <= this.nivelar.rango_nota
            //   ){
            //     return true;
            // }
            // return false
        },
        instruccionesNota(){
            return '0-'+this.nivelar.rango_nota
        },
        notaFinal(){
            if(this.progreso && this.progreso.progresos){
                let temasConNotas = this.progreso.progresos.filter(element=>{
                  return element.rango_nota && element.peso_nota
                })
                let notaF = 0;
                for (let i in temasConNotas){
                    let tema = temasConNotas[i]
                    let notaParcial = (tema.nota / tema.rango_nota) * tema.peso_nota
                    notaParcial = notaParcial.toPrecision(3)*1
                    notaParcial = notaParcial*5/100
                    notaF += notaParcial
                }
                return notaF.toPrecision(3);  
            }
            return null
        },
        notaFinalTemp(){
            if(this.notasTemas && this.notasTemas){
                let temasConNotas = this.notasTemas.filter(element=>{
                    return element.rango_nota && element.peso_nota
                })
                let notaF = 0;
                for (let i in temasConNotas){
                    let tema = temasConNotas[i]
                    let notaParcial = (tema.nota / tema.rango_nota) * tema.peso_nota
                    notaParcial = notaParcial.toPrecision(3)*1
                    notaParcial = notaParcial*5/100
                    notaF += notaParcial 
                }
                return notaF.toPrecision(3);	
            }
            return null
        },
        opcionesParaDefinirTema(){
            if(this.parcelador){
                let opciones = this.parcelador.map(element=>{
                  return {value:element.id, text:element.nombre}
                })
                return opciones
            }
            return []
        },
        puedeProcesarNotas() {
            if(this.notasTemas.filter(x => x.nota && x.tipo == 'autonomo').length == this.notasTemas.filter(x => x.tipo == 'autonomo').length) {
                return true;
            }
            return false
        }
    }
}
</script>

<style scope>
.headers{

}
.input-nota{
	width: 60px !important;
    display: inline !important;
    margin-right: 10px;
}
</style>